$text-color: #f8f9fa !default;
$table-color: #f8f9fa !default;
$nav-pills-link-active-bg: transparent !default;
$nav-pills-border-radius: 24px;

$flag-icon-css-path: "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags";
@import "~flag-icon-css/sass/flag-icon";
@import "~bootstrap/scss/bootstrap";
@import "./scrollbars";
@import "./css/range";
@import "./css/select";
@import "./css/tooltip";

$pto-white: #ffffff;
$pto-black: #000000;
$pto-red: #d10b0c;
$pto-darkblue: #0a0a1e;
$pto-blue: #10102f;
$pto-lightblue: #1a1a4c;

$t100-cyan: #00ffff;
$t100-darkblue: #02162d;
$section-dark: #212121;
$section-light: #202122;
$section: #28292a;
$subsection: #38393a;
$bordercolor: rgba(255, 255, 255, 0.1);
$head-nav: #1c1d1e;
$section-dark: #28292a;
$head-nav: #303132;
$subsection: #333;
$text: #f8f9fa;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$background: #ffffff;
$text: #272323;
$section: #ddd;
$subsection: #ffffff;
$head-nav: #ffffff;
$bordercolor: rgba(0, 0, 0, 0.1);
$section-light: #ffffff;

$footer-height: 60px;
$footer-margin: 40px;

$palette0: #f44336;
$palette1: #e91e63;
$palette2: #9c27b0;
$palette3: #673ab7;
$palette4: #3f51b5;
$palette5: #2196f3;
$palette6: #03a9f4;
$palette7: #00bcd4;
$palette8: #009688;
$palette9: #4caf50;
$palette10: #8bc34a;
$palette11: #cddc39;
$palette12: #ffeb3b;
$palette13: #ffc107;
$palette14: #ff9800;
$palette15: #ff5722;
$palette16: #795548;
$palette17: #9e9e9e;
$palette18: #607d8b;
$palette19: #ffffff;

$overall-color: #d10b0c;
$swim-color: #6cace4;
$run-color: #009639;
$bike-color: #ffcc00;

html {
	--background: #202020;
	--text: #ffffff;
	--subtext: #888888;
	--topbar: #303030;

	--topbar-height: 54px;
	--border-color: rgba(255, 255, 255, 0.1);

	--subsection: #282828;
	--sectionlight: #303030;
	--select-color: #282828;

	--info-bottom: #303030;
	--white: #ffffff;

	--ring-color: #444;

	--pto-red: #d10b0c;

	--swim-color: #6cace4;
	--bike-color: #ffcc00;
	--run-color: #009639;

	--color-navy: #001f3f;
	--color-blue: #0074d9;
	--color-aqua: #7fdbff;
	--color-teal: #39cccc;
	--color-olive: #3d9970;
	--color-green: #2ecc40;
	--color-lime: #01ff70;
	--color-yellow: #ffdc00;
	--color-orange: #ff851b;
	--color-red: #ff4136;
	--color-fuchsia: #f012be;
	--color-purple: #b10dc9;
	--color-maroon: #85144b;
	--color-white: #ffffff;
	--color-silver: #dddddd;
	--color-gray: #aaaaaa;
	--color-black: #111111;

	--color-swim: #0074d9;
	--color-bike: #2ecc40;
	--color-run: #ff851b;
	--color-overall: #d10b0c;

	&.bright {
		--background: #f2f2f2;
		--text: #3a3a3a;
		--border-color: #e0e0e0;
		--subsection: #cccccc;
		--info-bottom: #eee;
		--ring-color: #ddd;
		--select-color: #ffffff;
		--topbar: #ffffff;
	}

	@media (min-width: 992px) {
		--topbar-height: 70px;
	}
}

::-moz-selection {
	background: var(--pto-red);
	color: white;
}

::selection {
	background: var(--pto-red);
	color: white;
}

.zindex1 {
	z-index: 1;
}

.has-nav-tabs {
	margin-top: 52px;
}

.expand-details {
	i {
		&:hover {
			cursor: pointer;
		}
	}
}

.t100-results {
	.upcoming-races__title {
		img {
			height: 13px;
			margin-right: 12px;
			margin-bottom: 4px;
		}
	}

	.athlete-pic {
		background: url("./assets/t100-athlete-bg.png") !important;
		background-size: 100% !important;
	}

	.container:not(.upcoming-races > .container) {
		@media (min-width: 768px) {
			padding-top: 34px !important;
		}
	}

	.title {
		align-items: center;

		img {
			width: 100px;
			height: 19px;

			@media (min-width: 768px) {
				height: 28px;
				width: 145px;
			}
		}
	}

	.filters-wrapper {
		margin-inline: 12px;
	}

	.latest {
		margin-inline: 7px;
	}

	.title-and-link {
		display: none;

		.link-anchor {
			text-transform: uppercase;
			font-size: 13px;
			align-items: center;
		}

		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			align-self: center;
			max-width: 550px;
			width: 100%;
			padding-inline: 6px;
		}
	}

	.filters-wrapper + h2 {
		@media (min-width: 768px) {
			font-size: 25px;
			text-align: left;
		}
	}
}

.nav-tabs {
	background-color: black;
	z-index: 7;
	left: 0;
	border-bottom: none;
	position: fixed;
	top: 54px;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 52px;
	gap: 32px;

	@media (max-width: 400px) {
		gap: 28px;

		.nav-item {
			&:last-child {
				max-width: 82px;
			}
		}

		.nav-link {
			font-size: 11px !important;
		}
	}

	.nav-link {
		color: white !important;
		font-family: Transducer;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
		line-height: 15px;
		text-align: center;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		border-bottom: 4px solid transparent;
		transition: all 350ms;

		&:hover {
			opacity: 0.75;
		}

		&::selection {
			background-color: #00ffff !important;
			color: white !important;
		}

		&.active {
			background-color: unset;
			color: #00ffff !important;
			border-color: #00ffff;
		}

		@media (max-width: 500px) {
			width: min-content;
		}
	}

	.nav-link,
	.nav-item {
		height: 100%;
	}

	@media (min-width: 992px) {
		top: 70px;

		.nav-link {
			font-size: 15px;
		}
	}

	@media (max-width: 400px) {
		gap: 15px !important;
		padding-inline: 10px;
		justify-content: center;

		.nav-link {
			font-size: 11px !important;
		}
	}

	@media (max-width: 500px) {
		gap: 28px;
		padding-inline: 10px;
		justify-content: center;
	}
}

@font-face {
	font-family: Avenir Next LT W04 Heavy Cn It;
	src: url(https://content.protriathletes.org/website/assets/fonts/avenir.woff2) format("woff2"),
	url(https://content.protriathletes.org/website/assets/fonts/AvenirNextCondensed.woff) format("woff");
}

@font-face {
	font-family: "Avenir Condensed";
	src: url(https://content.protriathletes.org/website/assets/fonts/AvenirNextCondensed.woff) format("woff");
}

@font-face {
	font-family: "Avenir Condensed";
	font-weight: 700;
	src: url(assets/fonts/AvenirNextCondensed.woff) format("woff");
}

@font-face {
	font-family: "Avenir Next Condensed";
	font-weight: 900;
	src: url(assets/fonts/AvenirNextCondensed-Heavy.woff) format("woff");
}

@font-face {
	font-family: "Avenir Next";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://content.protriathletes.org/website/assets/fonts/AvenirNext-Regular-08.ttf) format("truetype");
}

@font-face {
	font-family: "Avenir Next";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://content.protriathletes.org/website/assets/fonts/AvenirNext-Bold-01.ttf) format("truetype");
}

.headline {
	font-weight: 900;
	text-transform: uppercase;
}

.headline-italic {
	font-family: Avenir Next LT W04 Heavy Cn It, sans-serif;
	text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	font-weight: 900;
	margin-bottom: unset;
	&.normal {
		font-weight: normal;
	}

	&.italic {
		font-family: Avenir Next LT W04 Heavy Cn It, sans-serif;
		text-transform: uppercase;
	}
}

.signature {
	display: none;
}

.pto-red {
	color: $pto-red;
}
.bg-default {
	background-color: var(--background);
}
.bg-transparent {
	background-color: transparent;
}
.bg-white {
	background-color: white;
}
.max-w-600 {
	max-width: 600px;
}
.max-w-800 {
	max-width: 800px;
}
.max-w-900 {
	max-width: 900px;
}
.max-w-1000 {
	max-width: 1000px;
}
.cursor-pointer {
	cursor: pointer;
}
html {
	overscroll-behavior-y: none;
}

body {
	background-color: var(--background);
	color: var(--text);
	overscroll-behavior: none;
	overscroll-behavior-y: none;
	max-height: -webkit-fill-available;
	margin: 0;
	padding: 0;
	overflow-y: hidden;
	height: 100vh;
	font-family: "Lato", sans-serif;
	overflow-x: hidden;

	&.yscroll {
		overflow-y: auto;
		max-height: unset;
	}

	&.noscroll {
		position: static;
		overflow: hidden;
	}
}

.content-area {
	padding-top: var(--topbar-height);
	max-height: 100%;
	min-height: 100%;
	max-width: 100%;
}

body.yscroll .content-area {
	max-height: unset;
}

body.fullscreen {
	.main {
		display: flex;
	}
}

h1 {
	font-size: 46px;
}
h2 {
	font-size: 35px;
}
.no-text-transform {
	text-transform: unset;
}

.py-8 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

@media (max-width: 768px) {
	h1 {
		font-size: 32px;
	}
	h2 {
		font-size: 24px;
	}
}

#gen-time {
	font-size: 12px;
}

.btn-primary {
	background-color: var(--pto-red);
	border-color: var(--pto-red);
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($pto-red, 50%);
	}
	&:hover,
	&:active,
	&:focus {
		background-color: var(--pto-red) !important;
		border-color: var(--pto-red) !important;
	}
}
.btn-link {
	color: #888;
	outline: 0;
	box-shadow: unset !important;
	&:hover {
		color: var(--pto-red);
	}
}

.btn-danger {
	font-weight: bold;
	border-radius: 0;
	background-color: var(--pto-red);
	outline: 0;
	box-shadow: unset !important;
	&:hover {
		color: white !important;
	}
}

.pto-btn {
	--pto-btn-hue: 360;

	text-align: center;
	white-space: nowrap;
	&.wrap {
		white-space: unset;
	}
	display: inline-block;
	font-size: 14px;
	line-height: 16px;
	margin: 6px 2px 6px 2px;
	cursor: pointer;
	padding: 12px 20px;
	border-radius: 100px;
	font-weight: bold;

	-webkit-tap-highlight-color: transparent;

	transition: color 250ms, background-color 250ms;

	background-color: white;
	border: 1px solid var(--text);
	color: var(--text);
	&:hover {
		background-color: #f0f0f0;
		color: var(--text) !important;
	}

	&.red {
		color: hsla(var(--pto-btn-hue), 90%, 43%, 1);
		background-color: hsla(var(--pto-btn-hue), 90%, 43%, 0.1);
		border: 1px solid hsla(var(--pto-btn-hue), 90%, 43%, 1);

		&:hover {
			color: hsla(var(--pto-btn-hue), 90%, 43%, 1) !important;
			background-color: hsla(var(--pto-btn-hue), 90%, 43%, 0.2);
		}
	}

	&.black {
		background-color: #333;
		color: white;
		border: 1px solid black;

		&:hover {
			color: white !important;
			background-color: #000;
		}
	}
}

.link-anchor {
	color: var(--text);
	display: inline-flex;
	align-content: center;
	justify-content: center;

	.material-icons-round {
		font-size: unset;
		line-height: unset;
		color: var(--pto-red);
	}

	position: relative;

	span {
		z-index: 1;
		align-self: center;
	}

	&:before {
		opacity: 0;
		transform: scaleX(0.5);
		position: absolute;
		content: "";
		border-radius: 12px;
		top: -0.05em;
		left: -0.5em;
		width: calc(100% + 0.6em);
		height: calc(100% + 0.1em);
		background-color: rgba(255, 255, 255, 0.15);
		transition: opacity 300ms, transform 300ms;
	}

	&:after {
		transition: background-color 300ms;
		z-index: 1;
		color: var(--pto-red);
		font-size: 0.75em;
		padding-left: 0.35rem;
		display: flex;
		align-items: center;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		content: "\f054";
	}

	&:hover {
		color: var(--text) !important;
		&:before {
			opacity: 1;
			transform: scaleX(1);
		}
	}
}

#athlete-popover {
	display: flex;
	top: 0;
	left: 0;
	position: absolute;
	width: 454px;
	background-color: var(--info-bottom);
	box-shadow: 0 0 16px #000;
	z-index: 400;
	visibility: hidden;
	transition: opacity 250ms, visibility 250ms;
	opacity: 0;

	.athlete-info {
		padding-inline: 32px;
		padding-block: 24px 28px;
	}

	.info-block {
		width: 100% !important;
	}

	.nopic {
		width: 100% !important;
		height: 100% !important;
	}

	.t100-contracts {
		display: none;
	}

	.athlete-info .rank .name {
		font-size: 12px !important;
	}

	.athlete-info .rank {
		padding-inline: 0.5rem;
	}

	.t100-contract {
		border-top: 1px solid #e5e5e5;
		grid-column: 1 / -1;
		margin-top: 20px;
		padding-top: 20px;
		display: flex !important;
		align-items: center;

		.name {
			text-transform: uppercase;
			color: #888888;
			font-size: 14px;
			font-weight: 700;
			margin-right: 12px;
		}

		img {
			max-height: 23px;
		}
	}

	.athlete-pic-group,
	.visit-profile {
		display: revert !important;
	}

	.info-block > .d-flex:first-child > .athlete-pic-group {
		display: none !important;
	}

	.info-container {
		width: 100%;
		padding-left: 0;
		column-gap: 14px;
		display: grid !important;
		grid-template-columns: min-content 1fr;

		& > * {
			grid-column: 1 / -1;
		}

		.link-anchor {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
			display: block !important;

			&:nth-child(2) {
				align-self: end;
			}

			&:nth-child(3) {
				align-self: start;
				grid-row: 2 / 3;
				font-size: 14px;
				font-weight: 700;
				margin-top: 6px;
				display: flex;
				align-items: center;

				&::after {
					display: unset;
				}
			}
		}

		.athlete-pic-group {
			grid-row: 1 / 3;
			grid-column: 1 / 2;
		}

		.link-anchor:nth-child(2)::after {
			display: none;
		}
	}

	&.active {
		opacity: 1;
		visibility: unset;
	}

	.athlete-pic {
		width: 105px;
		height: 105px;
		border-radius: 7px;
	}

	h2 {
		font-size: 20px !important;
		line-height: 24px !important;
	}

	.attribute-group {
		padding-bottom: 0 !important;
		font-size: 12px;
		justify-content: center;
		.attribute {
			flex-shrink: 0;
		}
	}

	.info-block {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h1 {
			text-align: center;
		}
	}
	.ring_progress {
		width: 50px;
		height: 60px;
	}
	.social {
		grid-column: 2 / -1;
	}
}

.dotsep:before {
	content: " \00b7";
	margin: 0 8px;
	text-decoration: none !important;
}

.border-4 {
	border-radius: 4px;
}
.border-bottom {
	border-bottom: 1px solid var(--border-color) !important;
}
.text-grey {
	color: #888;
}
.rotate-sm {
	@media (max-width: 768px) {
		transform: rotate(-90deg);
	}
}
.section {
	border-radius: 6px;
	padding: 16px 16px;
	margin-bottom: 10px;
	width: 100%;
	border: 1px solid var(--border-color);
}

.section-bottom {
	padding: 0;
	border-bottom: 1px solid var(--border-color);
	&.noborder {
		border-bottom: 0;
	}
	&.bright {
		background-color: #242424;
	}
}

.background-distress-bright {
	background: url(https://content.protriathletes.org/website/assets/background_texture_2.png);
	background-size: 100% 100%;
}

.background-distress-dark {
	background: url(https://content.protriathletes.org/website/assets/background_texture_down.png);
	background-size: 100% 100%;
}

.hide-if-empty:empty {
	display: none !important;
}

.select {
	position: relative;
	select {
		background-color: var(--select-color) !important;
		appearance: none;
		-webkit-appearance: none;
		padding-right: 24px;
	}
	&:after {
		pointer-events: none;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 16px;
		height: 100%;
		top: 0;
		color: black;
		right: 10px;

		transition: background-color 300ms;
		font-size: 15px;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		font-family: "Font Awesome 5 Free";
		font-weight: 700;
		content: "\f078";
	}
}

.form-control,
.form-control-sm {
	background: transparent;
	border-color: var(--border-color);
	color: var(--text);
	&:focus {
		background: transparent;
		color: var(--text);
		border-color: var(--pto-red);
		box-shadow: 0 0 0 0.2rem rgba($pto-red, 0.25);
		outline: 0;
	}
}

.table {
	margin-bottom: unset;

	td {
		border-color: var(--border-color);
		padding: 3px 2px;
		vertical-align: middle;
	}

	th {
		border: 0;
		font-weight: unset;
		color: #888;
		padding: 5px 2px;
		vertical-align: middle;
	}

	.table-sort {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}

a {
	color: var(--text);
	transition: color 250ms;
	&[href]:hover {
		color: $pto-red;
		text-decoration: none;
		&.btn {
			color: white;
		}
	}
	&:not([href]):hover {
		color: var(--text);
		text-decoration: none;
		&.btn {
			color: white;
		}
	}
}

.table {
	color: var(--text);
	tr.noborder,
	&.noborder {
		td {
			border: 0;
		}
	}
}

.f10 {
	font-size: 10px;
}
.f12 {
	font-size: 0.8rem;
}
.f14 {
	font-size: 14px;
}

.f110 {
	font-size: 1.1rem;
}
.f90 {
	font-size: 0.9rem;
}
.f80 {
	font-size: 0.8rem;
}

.latest {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	img {
		width: 100%;
		height: auto;
	}

	.title {
		margin: 0;
		font-weight: bold;
	}

	.preview {
		padding: 8px;
		margin-bottom: 20px;
		width: 25%;

		@media (max-width: 992px) {
			width: 50%;
		}

		@media (max-width: 576px) {
			width: 100%;
		}
	}

	.category {
		font-size: 0.85rem;
		display: inline-flex;
		text-transform: uppercase;
		padding: 0.2em 1em;
		background-color: var(--pto-red);
		color: white;
		margin: 0.25rem 0;
	}
}

div.pto-world-rankings--t100 {
	padding-top: 80px !important;

	@media (min-width: 992px) {
		padding-top: 110px !important;
	}
}

div.pto-world-rankings--t100,
div.t100-results {
	h1 {
		font-family: Transducer !important;
		text-transform: uppercase;
		color: black !important;
		font-weight: 600 !important;
		position: relative;
		margin-bottom: 2px;
		padding-left: 32px;
		font-size: 26px;

		@media (min-width: 768px) {
			padding-left: 46px;
			margin-bottom: 3px;
			font-size: 36px;
		}

		&::before {
			top: 50%;
			left: 14px;
			content: "";
			width: 1px;
			height: 38px;
			display: block;
			position: absolute;
			background-color: #101010;
			transform: translateY(-50%) rotate(28deg);

			@media (min-width: 768px) {
				left: 21px;
				height: 54px;
			}
		}
	}
}

div.pto-world-rankings--t100 {
	color: white !important;
	padding-bottom: 72px !important;
	background-color: #04152d;
	position: relative;
	padding-top: 37px !important;

	.pto-world-rankings__clear-filters,
	.explainer {
		font-family: aktiv-grotesk !important;
	}

	.races-complete-and-points {
		* {
			font-family: aktiv-grotesk !important;
		}
	}

	.rank-details {
		font-family: aktiv-grotesk !important;
	}

	::selection {
		background-color: #00ffff;
		color: white;
	}

	.pto-world-rankings__logo {
		justify-content: flex-start;

		h1 {
			padding-left: 0 !important;
			color: white !important;
			font-size: 21px !important;
			font-family: Transducer-Extended !important;

			&::before {
				content: unset !important;
			}
		}
	}

	.pto-world-rankings__header {
		align-items: flex-end !important;

		h2 {
			font-size: 16px !important;
			text-align: left !important;
			font-family: Transducer-Extended !important;
		}

		.link-anchor {
			color: #36ffff;
			font-size: 14px;
			margin-bottom: -2px;
			font-weight: 600 !important;
			font-family: Transducer-Extended !important;

			&::after {
				color: #36ffff;
			}
		}
	}

	.explainer {
		display: flex;
		font-size: 13px !important;
		margin-bottom: 15px !important;

		&::before {
			content: "";
			display: block;
			min-width: 16px;
			margin-top: 2px;
			height: 16px;
			background-color: #00ffff;
			margin-right: 10px;
		}
	}

	a.link-anchor {
		transition: all 350ms;

		&:hover {
			color: #00ffff !important;
			opacity: 0.75;
		}
	}

	.rankings {
		.trow {
			margin-top: 0 !important;

			.name {
				z-index: 2;
				height: 100%;
				align-items: flex-start !important;
				margin-top: 5px !important;
				justify-content: flex-start;

				a {
					font-size: 9px;
					font-family: Transducer-Extended;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: calc(100% - 26px);
					overflow: hidden;

					@media (min-width: 500px) {
						font-size: 11px;
					}

					&:hover {
						color: #00ffff;
					}
				}

				svg {
					width: 60px !important;
					margin-top: -13px;
				}

				.t100-logo.t100-logo--wildcard {
					@media (min-width: 992px) {
						svg {
							margin-top: -5px !important;
						}

						span {
							left: 23px !important;
							top: 26px !important;
							font-size: 8px !important;
						}
					}
				}

				.t100-logo--wildcard {
					position: relative;

					svg {
						margin-top: -17px;
					}

					span {
						position: absolute;
						font-size: 7px !important;
						top: 0px;
						left: 9px;
					}
				}
			}
		}

		.expand-details {
			color: black;
			font-size: 19px;
		}

		.rank-details .rank-row:not(.head) .points.red {
			color: #0f0f0f !important;
			background-color: white !important;
		}

		.rank-details .rank-row.rank-row--dnp:not(.head) .points.red {
			color: #0f0f0f !important;
			background: #818996 0% 0% no-repeat padding-box !important;
		}

		> .trow:nth-child(-n + 20) {
			.p-1 {
				position: relative;

				&::before {
					content: "";
					display: block;
					width: calc(100% + 50px);
					height: 100%;
					position: absolute;
					top: 0;
					left: -50px;
					z-index: 0;
					background: transparent linear-gradient(90deg, #36ffff 0%, #3cffca 100%) 0% 0% no-repeat padding-box;
				}
			}

			.ranking-number {
				position: absolute;
				z-index: 1;

				&::before {
					color: white !important;
					z-index: -1;
				}
			}

			.name {
				a {
					&:hover {
						color: white !important;
					}
				}
			}
		}

		> .trow:nth-child(-n + 18) {
			.ranking-number {
				margin-left: 11px !important;
			}
		}

		.athlete-pic {
			width: 48px !important;
			height: 48px !important;
		}
	}

	.ranking-number {
		margin-left: 13px;
		color: black !important;
		font-size: 19px !important;
		font-weight: 700 !important;
		font-family: Transducer !important;

		&::before {
			font-size: 19px !important;
			left: -14px !important;
			top: -5px !important;
		}
	}

	.pto-world-rankings__filters {
		padding-top: 17px !important;

		select {
			transition: all 350ms;

			&:focus {
				border-width: 2px !important;
				border-color: #00ffff !important;
			}
		}
	}

	.pto-world-rankings__clear-filters {
		&:hover {
			color: #00ffff;
		}
	}

	@media (min-width: 768px) {
		padding-top: 0 !important;

		.h2 {
			width: unset !important;
		}

		.pto-world-rankings__logo {
			h1 {
				font-size: 27px !important;
			}
		}

		.pto-world-rankings__header {
			margin-bottom: 18px;
		}

		.pto-world-rankings__filters {
			padding: 34px 27px !important;
			margin-bottom: 60px;

			form#form-rankings {
				row-gap: 16px;
			}
		}

		.rankings .rank-details .rank-row.title div:last-child {
			font-size: 14px !important;
		}

		.rankings .athlete-pic {
			width: 51px !important;
			height: 51px !important;
		}

		.rankings {
			.trow {
				.name {
					margin-top: 0 !important;
					align-items: center !important;

					a {
						font-size: 15px !important;
					}

					.t100-logo {
						margin-top: 0 !important;
						width: 100px;
						height: 100px;
						position: relative;
						justify-content: center !important;

						svg {
							margin-top: 6px !important;
							width: 100px !important;
							height: 16px !important;
						}

						span {
							left: 24px !important;
							top: 29px !important;
							font-size: 8px !important;
						}

						&::before {
							content: "";
							display: block;
							background-color: white;
							position: absolute;
							top: -8px;
							width: 151%;
							height: 60px;
							z-index: -1;
							right: -30px;
							transform: skewX(-30deg);
						}
					}
				}

				.races-complete-and-points {
					z-index: 2;
				}
			}
		}

		.rankings {
			.trow {
				margin-bottom: 4px;
			}
		}

		a.link-anchor {
			transition: all 350ms;
		}
	}

	.pto-world-rankings__logo {
		display: flex;
		align-items: center;

		img {
			filter: unset !important;
			width: 100px;
			height: 19px;

			@media (min-width: 768px) {
				width: 146px;
				height: 28px;
			}
		}

		&::after {
			content: unset;
		}
	}

	div.trow > div:nth-child(4) {
		width: 42px !important;
	}

	.trow > .p-1 + div.races-complete-and-points {
		background-color: #101010 !important;
	}
	@media (max-width: 400px) {
		.athlete-pic {
			display: none !important;
		}

		.races-complete-and-points {
			display: none !important;
		}

		.trow > div {
			height: 54px;
		}

		.rankings .rank-details .rank-row {
			font-size: 10px !important;
		}

		.rank-details .rank-row.head img {
			width: 32px !important;
		}

		.points {
			width: 24px !important;
		}
	}

	.races-complete-and-points {
		display: flex;
		padding-inline: 0 !important;
		width: 100%;
		flex-direction: column;

		.points {
			width: 100%;
			font-size: 18px;
			justify-content: flex-end !important;

			&:last-child {
				justify-content: center !important;
				height: 100%;
			}
		}

		.races-completed {
			display: inline-block;
			width: 100%;
			color: white;
			padding-inline: 0;
			padding-bottom: 4px;
			font-size: 10px;
			line-height: 10px;
			margin-top: -2px;

			span {
				color: #00ffff;
			}

			img {
				height: 9px;
				margin-right: 3px;
			}

			.grey {
				color: lightgrey;
			}
		}

		.points {
			display: inline-block;
			color: #00ffff !important;
		}

		@media (min-width: 768px) {
			gap: 4px;
			padding-top: 3px !important;

			.races-completed {
				font-size: 12px;
				margin-top: 1px;
				line-height: 10px;

				img {
					height: 11px;
				}
			}

			.points {
				line-height: 18px;
				font-size: 21px;
			}
		}
	}

	.explainer {
		margin-top: 0px;
		font-size: 13px;

		a {
			color: #d10b0c;
			text-decoration: underline;
		}
	}

	.trow > div:nth-child(1) {
		width: 50px !important;
	}

	.ranking-number:before {
		color: #00ffff !important;
	}

	.athlete-pic {
		background: url("./assets/t100-athlete-bg.png") !important;
	}

	#form-rankings {
		div:nth-child(3),
		div:nth-child(4) {
			grid-row: 2 / 3;
		}
	}

	.rankings .rank-details {
		position: relative;
		padding-top: 17px;
		background-color: #02162d !important;

		@media (min-width: 768px) {
			padding-top: 10px;
		}

		.rank-row.title {
			gap: 12px;
			flex-direction: row;
			justify-content: flex-start;

			@media (min-width: 768px) {
				padding-bottom: 18px;
			}

			img {
				height: 17px;
			}

			div:last-child {
				font-size: 19px;
				font-weight: 600;
				color: white !important;
				background-color: transparent !important;
			}
		}

		.rank-row {
			padding: 3px 16px;
			font-size: 13px;

			@media (min-width: 768px) {
				font-size: 15px;
			}

			& > * {
				display: flex;
				align-items: center;
			}

			// Did not participate
			&--dnp {
				color: #818b96 !important;

				&.rank-row:not(.head) .points,
				.race {
					color: #818b96 !important;
				}
			}

			&--qualifying {
				color: #00ffff !important;

				&.rank-row:not(.head) .points,
				.race {
					color: #00ffff !important;
				}

				&.rank-row:not(.head) .points:last-child {
					color: #02162d !important;
					background-color: #00ffff !important;
				}
			}

			&.head {
				padding-bottom: 3px;
				margin-bottom: 9px;
			}

			&:not(.head) {
				.points {
					padding: 3px 0;
					color: white !important;
					background-color: #1d2e43 !important;
				}
			}

			.position {
				width: 34px;
				text-align: left;
				margin-right: 4px;
				justify-content: flex-start;
			}

			.points {
				width: 48px;

				&:nth-last-child(2) {
					margin-right: 14px;

					@media (min-width: 768px) {
						margin-right: 20px;
					}
				}
			}

			.date {
				width: 54px;
			}

			@media (min-width: 768px) {
				.points {
					width: 68px;

					img {
						width: 61px;
						height: 16px;
					}
				}

				.position {
					width: 48px;
				}

				.date {
					width: 84px;
				}

				.time {
					width: 78px;
					text-align: left;
					justify-content: flex-start;
				}
			}
		}

		.legend {
			color: #00ffff;
			display: flex;
			align-items: center;
			gap: 6px;
			padding: 0 16px;
			margin-top: 6px;
			margin-bottom: 16px;
			font-size: 14px;

			&::before {
				content: "";
				width: 13px;
				height: 13px;
				display: block;
				background-color: #00ffff;
			}

			@media (min-width: 768px) {
				position: absolute;
				top: 12px;
				right: 0px;
			}
		}

		@media (min-width: 768px) {
			padding-top: 12px !important;
			padding-bottom: 12px !important;
		}
	}

	.rank-row .red {
		background-color: #00ffff !important;
		color: #101010 !important;

		&:nth-last-child(2) {
			color: white !important;
			background-color: #101010 !important;
		}
	}

	.rank-row .points,
	.rank-row .red {
		&:nth-last-child(3) {
			@media (max-width: 768px) {
				display: none !important;
			}
		}
	}
}

.pto-world-rankings {
	padding-inline: 12px;

	@media (min-width: 768px) {
		padding-top: 62px !important;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 34px;

		@media (min-width: 768px) {
			margin-top: 54px;
		}

		.h2,
		h1 {
			font-size: 21px;
		}

		a {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 14px;

			@media (min-width: 768px) {
				font-size: 17px;

				&::after {
					font-size: 17px;
					padding-left: 8px;
				}
			}

			&::after {
				font-size: 15px;
				font-weight: unset;
			}
		}
	}

	&__clear-filters {
		font-size: 13px;
		border: none;
		outline: none;
		padding-inline: 0 !important;
		margin-left: auto;
		background-color: transparent;
		font-weight: normal;
		color: #989898;
		text-decoration: underline;
		margin-top: 10px;
		grid-column: 1 / -1;

		@media (min-width: 768px) {
			margin-left: 0;
			margin-top: 0;
			grid-column: 3 / 5;
			grid-row: 2 / 3;
		}
	}

	&__filters {
		display: flex;
		flex-direction: column;
		border: 1px solid #e0e0e0;
		padding-inline: 16px;
		padding-block: 28px 22px;
		margin-top: 16px;
		margin-bottom: 24px;

		@media (min-width: 768px) {
			padding-inline: 21px;
			margin-bottom: 28px;
		}

		.h5 {
			font-size: 15px;
			font-weight: 700;

			@media (min-width: 768px) {
				font-size: 17px;
				font-weight: 900;
			}
		}

		i {
			margin-right: 7px;

			@media (min-width: 768px) {
				margin-right: 6px;
			}
		}

		form {
			row-gap: 9px;
			display: grid;
			column-gap: 18px;
			grid-template-columns: min-content 1fr;
			grid-template-rows: repeat(3, minmax(0, 1fr));
			align-items: center;

			@media (min-width: 768px) {
				grid-template-columns: repeat(4, auto);
				grid-template-rows: repeat(2, minmax(0, 1fr));
				justify-content: start;
				row-gap: 10px;
				column-gap: 19px;
			}

			& > div:first-child {
				margin-bottom: 5px;
			}

			.form-control-sm {
				border-radius: 6px;
			}

			.select {
				max-width: unset !important;
			}

			.radio-group {
				width: 100%;

				@media (min-width: 768px) {
					margin-right: 30px;

					.form-control-sm {
						font-size: 17px;
						width: 83px;
					}
				}

				& > input {
					flex: 1;
					justify-content: center;
				}
			}
		}
	}
}

.pto-world-rankings__logo,
.pto-stats {
	color: white !important;
	width: 100%;
	display: flex;
	align-items: center;
	height: 41px;
	justify-content: center;

	img {
		height: 38px;
		filter: invert(1);
	}

	&:after {
		margin-left: 12px;
		font-size: 41px;
		content: "WORLD RANKINGS";
		font-family: Lato;
		font-weight: 900;
		white-space: nowrap;
	}

	@media (max-width: 768px) {
		img {
			height: 30px;
		}

		&:after {
			margin-left: 10px;
			font-size: 26px;
		}
	}
}

.pto-stats {
	padding-left: 10px;

	&:after {
		content: unset;
	}

	@media (min-width: 992px) {
		padding-left: 22px;
	}

	img {
		height: 30px;
	}
}

.pto-logo-long {
	height: 36px;
}

#topbar-area {
	background-color: var(--topbar);
	border-bottom: 1px solid rgb(0, 0, 0, 0.1);
	height: var(--topbar-height);
	max-height: var(--topbar-height);
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 400;

	&:empty {
		height: 0;
		display: none;
	}
}

#search-results {
	color: white;
	min-width: 100%;
	width: fit-content;
	padding-top: 18px !important;
	top: calc(100% + 0px);
	left: 0;
	overflow-y: auto;
	z-index: 1;
	width: 100%;

	&.hide {
		display: none;
	}

	@media (max-width: 768px) {
		width: 100%;
		box-shadow: none;
	}

	@media (min-width: 992px) {
		padding-top: 25px !important;
	}
}

.search-result {
	background-color: white;
	border-radius: 9px;
	height: 65px;
	padding: 12px 16px 15px 16px;
	display: block;
	margin-bottom: 6px;
	position: relative;

	@media (min-width: 992px) {
		margin-bottom: 8px;
	}

	&:hover {
		.search-result__name {
			color: var(--pto-red);
		}
	}

	&--inactive {
		background-color: #ffffffb0;

		.search-result__type {
			color: black;

			&::after {
				content: "(Inactive)";
				margin-left: 3px;
			}
		}
	}

	&--athlete {
		display: flex;
		padding-top: 0;
		padding: 0 10px 0 10px;
		padding-bottom: 0;
		align-items: center;

		a {
			column-gap: 11px;
			display: grid !important;
			grid-template-columns: min-content 1fr;
			grid-template-rows: repeat(2, min-content);
		}

		.search-result__type {
			align-self: end;
		}

		.search-result__name {
			align-self: start;
		}

		.image-container {
			--size: 49px;
			--border-radius: 6px;

			grid-row: 1 / 3;
			grid-column: 1 / 2;
			position: relative;
			width: var(--size);
			height: var(--size);
			background-color: #e5e5e5;
			border-radius: var(--border-radius);

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				height: 100%;
				max-width: 100%;
				border-radius: var(--border-radius);
				left: 50%;
				transform: translateX(-50%);
				transition: all 300ms;
			}

			.nopic {
				width: 100%;
				height: var(--size) !important;
				object-fit: cover;

				&:after {
					position: absolute;
					content: "";
					top: 0;
					left: 0;
					width: calc(100%);
					height: calc(100%);
					background: url("https://content.protriathletes.org/website/assets/user-solid.svg");
					background-position: bottom;
					background-repeat: no-repeat;
					background-size: 70%;
					filter: invert(1) brightness(0.75);
				}
			}
		}
	}

	a {
		display: flex;
		flex-direction: column;
	}

	&__t100 {
		filter: invert(1);
		position: absolute;
		right: 18px;
		bottom: 17px;

		img {
			height: 10px;
		}
	}

	&__type {
		color: #888888;
		font-size: 11px;
		font-family: Lato, sans-serif;
	}

	&__name {
		transition: color 300ms;
		font-size: 15px;
		text-transform: uppercase;
		font-family: Lato, sans-serif;
		font-weight: bold;
		color: black;
	}
}

#head-to-head-input {
	background-color: white;
}

#search-close {
	color: white;
	padding: 0.25rem 1rem;
	font-size: 16px;
	cursor: pointer;
}

#search-toggle {
	--size: 39px;
	height: var(--size);
	width: var(--size);
	margin-right: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 20px;
	background-color: black;
	border-radius: 50%;

	span {
		display: none;
	}

	img {
		--size: 16px;
		width: var(--size);
		height: var(--size);
	}

	@media (min-width: 992px) {
		min-width: 197px;
		margin-right: 23px;
		gap: 18px;
		border-radius: 6px;
		height: 33px;
		color: white;
		font-size: 11px;
		justify-content: space-between;
		padding-left: 17px;
		padding-right: 14px;

		span {
			display: inline;
			font-size: 14px;
			margin-bottom: 2px;
		}

		img {
			--size: 14px;
		}
	}
}

.topbar {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	.pto-stats {
		img {
			filter: none;
		}
	}

	.world-rankings-logo {
		margin-left: 1rem;
		height: 41px;
	}

	.search-blur-overlay {
		display: none;
	}

	.search {
		position: absolute;
		background-color: black;
		margin: 0px;
		padding-top: 14px;
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
		color: white;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 300;
		flex-direction: column;
		transition: opacity 250ms, visibility 250ms;
		opacity: 1;
		visibility: unset;

		& > div {
			justify-content: space-between;

			.search-input-container {
				width: 100%;
				margin-top: 26px;
				position: relative;

				input {
					height: 33px;
					padding-left: 17px;
					padding-right: 17px;
					background-color: white;
					border: 1px solid #c6c6c6;
					border-radius: 20px;
					font-size: 13px;
					color: black !important;
					transition: all 350ms ease-in-out;

					&:active,
					&:focus {
						border-color: var(--pto-red);
					}
				}

				img {
					height: 16px;
					filter: invert(1);
					position: absolute;
					right: 12px;
					top: 8px;
				}
			}
		}

		#search-close {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;

			img {
				width: 17px;
			}
		}

		h2 {
			font-size: 24px;
			font-family: Lato, sans-serif;
			font-weight: 900;
		}

		&.hide {
			opacity: 0;
			visibility: hidden;
		}

		@media (min-width: 992px) {
			width: 532px;
			right: 0;
			padding-top: 25px;
			padding-left: 32px;
			padding-right: 40px;

			#search-close {
				img {
					width: 19px;
				}
			}

			.search-input-container {
				input {
				}
			}
		}
	}

	@media (min-width: 992px) {
		.search-blur-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			backdrop-filter: blur(10px);
			z-index: -1;
			width: 100vw;
			display: block;
			z-index: 3;
			height: 100vh;
			transition: all 175ms ease-in-out;
		}

		.search.hide + .search-blur-overlay {
			visibility: hidden;
			opacity: 0;
		}

		.submenu {
			display: flex;
			align-items: center;

			&__content {
				opacity: 0;
				visibility: hidden;
				transition: opacity 350ms, visibility 350ms;
				position: absolute;
				top: 80px;
				background-color: #000000;
				border-radius: 11px;

				&--visible {
					display: block;
					visibility: visible;
				}
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}

			// T100 submenu
			&:first-child {
				.submenu__button {
					img {
						margin-bottom: 3px;
					}
				}

				.submenu__content {
					width: 261px;
					padding: 24px 33px 23px 28px;
					background-color: #02162d;

					img {
						filter: invert(1);
						height: 17px;
					}

					li {
						a {
							color: white;
							font-size: 15px;
							text-transform: uppercase;
							font-family: Transducer-Extended, sans-serif;
							font-weight: 600;
							transition: filter 300ms;

							& + img {
								transition: filter 300ms;
							}

							&:hover {
								filter: brightness(0.65);

								& + img {
									filter: brightness(0.65);
								}
							}
						}

						&:nth-last-child(2) {
							margin-bottom: 7px;
						}

						&:last-child {
							padding-top: 3px;
							border-top: 1px solid white;

							img {
								filter: none;
								height: 13px;
								margin-bottom: 2px;
								margin-left: 6px;
							}
						}
					}

					&__background {
						height: auto !important;
						min-height: 354px;
						max-height: 354px;
						user-select: none;
						width: 100%;
					}
				}

				button {
					border-right: 1px solid #c6c6c6;
					padding-right: 25px;

					img {
						height: 18px;
					}
				}
			}

			// World Rankings submenu
			&:nth-child(2) {
				.submenu__content {
					width: 603px;
					right: 16px;
					gap: 10px;
					display: flex;
					padding: 27px 20px 43px 27px;

					@media (min-width: 1156px) {
						right: unset;
					}

					h2 {
						font-size: 13px;
						margin-bottom: 23px;
						font-weight: normal;
						color: white;
						font-family: Lato, sans-serif;
					}

					.image-container {
						grid-row: 1 / 3;
						grid-column: 2 / 3;
						margin-right: 21px;
						width: 45px;
						height: 45px;
						position: relative;
						border-radius: 10px;
						background: radial-gradient(circle, rgba(212, 111, 111, 1) 15%, rgba(211, 8, 8, 1) 85%);

						img {
							position: absolute;
							height: 100%;
							top: 0;
							border-radius: 10px;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
							max-width: 100%;
						}
					}

					li {
						a {
							display: grid;
							justify-content: start;
							align-items: center;
							grid-template-columns: repeat(3, auto);

							.name,
							.rank {
								transition: color 300ms;
							}

							&:hover {
								.name,
								.rank {
									color: var(--pto-red);
								}
							}
						}

						&:first-child {
							border-bottom: 1px solid #3d3d3d;

							.name {
								margin-top: 14px;

								span {
									&:first-child {
										font-size: 15px;
									}

									&:last-child {
										font-size: 22px;
										margin-top: -12px;
									}
								}
							}

							.image-container {
								width: 80px;
								height: 80px;
								border-radius: 0;
								background: transparent;

								img {
									border-radius: 0;
								}
							}
						}

						&:not(:last-child) {
							margin-bottom: 12px;
						}
					}

					.country {
						grid-row: 2 / 3;
						color: white;
						font-size: 10px;
						font-weight: 700;
						display: flex;
						align-items: center;
						margin-bottom: 8px;

						.flag-icon {
							border-radius: 50%;
							margin-right: 5px;
							--size: 12px;
							height: var(--size);
							width: var(--size);
						}
					}

					.name {
						font-family: "Avenir Next Condensed", sans-serif;
						text-transform: uppercase;
						color: white;
						grid-row: 1 / 2;
						display: flex;
						flex-direction: column;
						margin-top: 5px;

						span {
							margin: 0;

							&:first-child {
								font-size: 12px;
							}

							&:last-child {
								margin-top: -8px;
								font-size: 18px;
								display: block;
							}
						}
					}

					.rank {
						grid-column: 1 / 2;
						grid-row: 1 / 3;
						color: white;
						margin-right: 24px;
						font-size: 19px;
						font-family: "Avenir Next Condensed", sans-serif;
					}

					& > div {
						width: 50%;

						& > a {
							margin-top: 30px;
							background-color: var(--pto-red);
							width: 169px;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 33px;
							color: white;
							font-size: 13px;
							border-radius: 5px;
							transition: filter 300ms;

							&:hover {
								filter: brightness(1.1);
							}
						}
					}
				}

				button {
					border-right: 1px solid #c6c6c6;
					padding-left: 27px;
					padding-right: 27px;

					img {
						height: 27px;
					}
				}
			}

			// Races submenu
			&:last-child {
				.submenu__content {
					width: 456px;
					display: flex;
					gap: 30px;
					right: 16px;
					padding: 29px 35px 27px 30px;

					@media (min-width: 1340px) {
						right: unset;
					}

					a {
						text-align: center;

						.image-container {
							width: 180px;
							height: 92px;
							border-radius: 12px;
							overflow: hidden;
							position: relative;
						}

						img {
							transition: all 300ms;
							position: absolute;
							top: 0;
							left: 50%;
							transform: translateX(-50%);
							height: 100%;
							min-width: 100%;
							bottom: 0;
						}

						span {
							color: white;
							margin-top: 16px;
							display: block;
							transition: color 300ms;
							font-size: 19px;
							font-family: "Avenir Next Condensed";
							text-transform: uppercase;
						}

						&:hover {
							span {
								color: rgb(209, 209, 209);
							}

							img {
								transform: translateX(-50%) scale(1.08);
							}
						}
					}
				}

				button {
					padding-left: 28px;

					& > span {
						font-size: 23px;
						font-weight: 600;
						text-transform: uppercase;
						font-family: "avenir next condensed", Sans-serif;
					}
				}
			}

			@media (min-width: 992px) and (max-width: 1042px) {
				&:first-child {
					button {
						padding-right: 18px;
					}
				}

				&:nth-child(2) {
					button {
						padding-left: 18px;
						padding-right: 18px;
					}
				}

				&:last-child {
					button {
						padding-left: 18px;
					}
				}
			}

			&__button {
				height: 39px;
				background-color: transparent;
				border: none;
				outline: none;

				&--active,
				&:hover {
					i {
						color: var(--pto-red);
					}
				}

				& > img,
				& > span {
					margin-right: 11px;
				}

				i {
					transition: color 250ms;
					font-size: 19px;
				}
			}
		}
	}

	.menu {
		font-size: 17px;
		flex-grow: 1;
		display: flex;
		height: 100%;
		justify-content: center;

		@media (min-width: 992px) {
			padding: 0 10px;
		}

		& > a {
			text-transform: uppercase;
			font-family: Avenir Condensed;
			font-weight: bold;
			display: flex;
			align-items: center;
			padding: 0 20px;
			height: 100%;
			text-decoration: unset;
			border-radius: 0;
			white-space: nowrap;
			transition: background-color 250ms;
			width: fit-content;

			&:hover {
				cursor: pointer;
				position: relative;
				color: var(--pto-red);

				&:after {
					position: absolute;
					content: "";
					width: 100%;
					height: 6px;
					background-color: var(--pto-red);
					bottom: 0;
					left: 0;
				}
			}
		}
		@media (max-width: 991px) {
			display: none;
		}
	}
}

#menu-toggle {
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	width: var(--topbar-height);
	height: var(--topbar-height);
	background-color: var(--pto-red);

	@media (min-width: 992px) {
		display: none;
	}

	&.cross {
		display: flex !important;
	}

	i {
		left: 0;
		position: relative;
		height: 2px;
		width: 17px;
		background-color: white;
		transition: background-color 200ms;

		&:before {
			content: " ";
			position: absolute;
			top: -6px;
			background-color: white;
			width: 17px;
			height: 2px;
			transition: transform 200ms, top 200ms;
		}

		&:after {
			content: " ";
			position: absolute;
			top: 6px;
			background-color: white;
			width: 17px;
			height: 2px;
			transition: transform 200ms, top 200ms;
		}
	}

	&.cross {
		i {
			background-color: transparent;

			&:before {
				transform-origin: 50% 50%;
				transform: rotate(-45deg);
				top: 0;
			}

			&:after {
				transform-origin: 50% 50%;
				transform: rotate(45deg);
				top: 0;
			}
		}
	}
}

#side-menu {
	position: fixed;
	left: 0;
	top: 0;
	height: calc(100vh);
	display: flex;
	flex-direction: column;

	#side-menu-background {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: opacity 250ms, visibility 250ms;
	}

	.side-menu__items {
		flex-direction: column;
		display: flex;
		gap: 0.75rem;

		> li a {
			text-transform: uppercase;
			font-family: Avenir Condensed;
			font-weight: bold;
			font-size: 18px;
		}

		> li:nth-child(4) ul {
			li {
				padding-bottom: 0.75rem;
			}

			a {
				font-size: 15px;
			}
		}
	}

	.menu {
		--topbar-height: 54px;
		transition: opacity 250ms, visibility 250ms;
		position: absolute;
		display: flex;
		justify-content: unset;
		width: 100vw;
		background-color: var(--background);
		flex-direction: column;
		align-items: flex-start;
		z-index: 300;
		height: calc(100% - var(--topbar-height));
		flex-grow: 1;
		top: var(--topbar-height);
		flex-shrink: 1;
		overflow-y: auto;
		left: 0;
		opacity: 0;
		visibility: hidden;

		img {
			height: 41px;
		}

		ul {
			padding: 0;
			margin: 0;
			width: 100%;
			list-style: none;
		}

		&__t100 {
			width: 100%;
			position: relative;

			&__content {
				position: absolute;
				padding-inline: 13px;
				top: 44px;
				width: 100%;

				img {
					filter: invert(1);
					height: 17px;
				}

				ul {
					padding-top: 27px;
				}

				li {
					margin-bottom: 12px;

					a {
						color: white;
						font-size: 12px;
						text-transform: uppercase;
						font-family: Transducer-Extended, sans-serif;
						font-weight: 600;
					}

					&:nth-last-child(2) {
						margin-bottom: 18px;
					}

					&:last-child {
						padding-top: 12px;
						border-top: 1px solid white;

						img {
							filter: none;
							height: 11px;
							margin-bottom: 1px;
							margin-left: 11px;
						}
					}
				}
			}

			&__background {
				height: auto !important;
				user-select: none;
				min-height: 354px;
				max-height: 354px;
				width: 100%;
			}
		}

		&__rankings,
		&__races {
			padding-top: 32px;
			padding-inline: 14px;

			img {
				height: 26px;
			}

			ul {
				margin-top: 11px;
			}

			li {
				margin-bottom: 7px;

				a {
					font-size: 13px;
					font-weight: bold;
					font-family: Lato, sans-serif;
				}
			}

			& > span {
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 700;
				font-family: "avenir next condensed", Sans-serif;
			}
		}

		&__races {
			padding-top: 24px;

			ul {
				margin-top: 4px;
			}
		}

		.shortcuts-container {
			overflow-y: auto;
			width: 100%;
		}
	}

	&.show {
		display: flex;

		.menu {
			opacity: 1;
			visibility: visible;
		}

		#side-menu-background {
			opacity: 1;
			visibility: visible;
		}

		.footer-links {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.main-area {
	flex-grow: 1;
	width: 100%;
	transition: opacity 100ms;

	&.hide {
		opacity: 0;
	}
}

.main {
	margin-top: 0;
	height: 100%;
}

.main-footer {
	background-color: #dcdbdc;
	padding: 45px 21px 7px 21px;

	@media (min-width: 992px) {
		padding: 45px 21px 74px 21px;
	}

	.logo {
		filter: invert(1);
		height: 32px;
		margin-bottom: 27px;
	}

	.menu--rankings {
		margin-bottom: 33px;
	}

	.menu--races {
		margin-bottom: 42px;
	}

	.contact-form {
		height: 0;
	}

	.menu--t100 {
		margin-left: -22px;
		margin-right: -22px;
		height: 303px;
		width: 100vw;
		position: relative;

		&__content {
			position: absolute;
			padding-inline: 26px;
			top: 28px;

			img {
				height: 17px;
			}

			ul {
				padding-top: 23px;
			}

			.links {
				padding-right: 16px;
			}

			li {
				margin-bottom: 1px !important;

				a {
					color: white;
					font-size: 15px;
					text-transform: uppercase;
					font-family: Transducer-Extended, sans-serif;
					font-weight: 600;
				}

				&:nth-last-child(2) {
					margin-bottom: 8px !important;
				}

				&:last-child {
					padding-top: 23px;
					border-top: 1px solid white;

					img {
						filter: none;
						height: 13px;
						margin-bottom: 3px;
						margin-left: 5px;
					}
				}
			}

			.socials {
				gap: 9px;
				display: flex;
				align-items: center;

				li {
					--size: 32px;
					height: var(--size);
					margin: 0 !important;
					padding: 0 !important;
					margin-bottom: 0 !important;
					border-top: none !important;

					i {
						color: #02162d;
						font-size: 15px;
					}

					a {
						margin: 0;
						height: var(--size);
						width: var(--size);
						border-radius: 100%;
						background-color: #00ffff;
					}
				}
			}
		}

		&__background {
			height: auto !important;
			min-height: 354px;
			max-height: 354px;
			user-select: none;
			width: 100vw;
		}
	}

	header {
		text-transform: uppercase;
		font-size: 16px;
		margin-bottom: 4px;
		font-family: "Avenir Next Condensed", sans-serif;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		&:not(:last-child) {
			margin-bottom: 5px;
		}

		a {
			font-size: 13px;
			font-weight: bold;
			font-family: Lato, sans-serif;
		}
	}

	@media (min-width: 992px) {
		display: flex;
		padding-top: 59px;
		justify-content: center;

		.contact-form {
			height: 795px;
			background-color: #001f3f;
		}

		.logo {
			height: 38px;
			margin-bottom: 40px;
			grid-column: 1 / 3;
		}

		.main-footer__links {
			display: grid;
			grid-template-rows: repeat(3, min-content);
		}

		.main-footer__inner {
			gap: 134px;
			display: flex;
			width: 100%;
			max-width: 1140px;
			padding-inline: 12px;

			& > * {
				flex: 1;
			}
		}

		.menu--races,
		.menu--rankings {
			margin-bottom: 42px;

			li {
				margin-bottom: -3px;
			}
		}

		.socials {
			a {
				transition: filter 300ms;

				& + img {
					transition: filter 300ms;
				}

				&:hover {
					filter: brightness(0.65);

					& + img {
						filter: brightness(0.65);
					}
				}
			}
		}

		.menu--races {
			grid-column: 2 / 3;
		}

		.menu--rankings {
			grid-column: 1 / 2;
		}

		.menu--t100 {
			grid-column: 1 / 3;
			width: unset;
			margin-left: 0;
			margin-right: 0;
			--border-radius: 18px;
			border-radius: var(--border-radius);

			li {
				a {
					transition: filter 300ms;

					& + img {
						transition: filter 300ms;
					}

					&:hover {
						filter: brightness(0.65);

						& + img {
							filter: brightness(0.65);
						}
					}
				}
			}

			&__background {
				user-select: none;
				width: 100%;
				border-radius: var(--border-radius);
			}
		}
	}
}

.socials {
	padding-top: 1rem;

	a {
		background-color: var(--pto-red);
		color: white;
		width: 2.5rem;
		height: 2.5rem;
		font-size: 1.25rem;
		border-radius: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 1rem;
		transition: background-color 250ms, color 250ms;

		&:hover {
			background-color: black;
			color: white;
		}
	}
}

.content {
	max-width: 1100px;
	margin: 0 auto;
}

.head-nav {
	margin: 10px 0 20px;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;

	a {
		color: var(--subtext);
		padding: 8px 16px;
		margin-right: 4px;
		text-decoration: unset;
		font-weight: bold;
		white-space: nowrap;

		&:hover {
			color: $pto-red;
		}

		&[selected] {
			border-bottom: 2px solid $pto-red;
			color: $pto-red;
		}
	}
}

.race-name-and-tier {
	display: flex;
	flex-direction: column;

	.racename {
		justify-content: flex-start !important;
	}

	& > span {
		font-size: 14px;
		color: #888;
		font-weight: 700;
	}
}

.latest {
	.race-list {
		display: flex;
		overflow: hidden;
		flex-wrap: wrap;
		max-width: 550px;

		@media (max-width: 992px) {
			max-height: unset;
		}

		@media (min-width: 992px) {
			width: 100%;
		}
	}

	.race {
		width: 100%;
		border: 1px solid var(--border-color);
		margin: 4px 4px;
		padding: 12px 12px 12px 12px;

		.racename {
			font-size: 1rem;
		}
	}

	.info {
		margin-top: 4px;
		text-decoration: none;

		.racename {
			font-size: 14px;
			font-weight: bold;
		}

		.racename:hover {
			color: $palette6;
		}
	}

	.details {
		font-size: 12px;
		color: #888;
	}

	.top3 {
		@media (min-width: 768px) {
			margin-top: -21px;
		}

		.position {
			width: 12px;
			min-width: 12px;
			text-align: right;
			margin-right: 6px;
		}

		.flag-icon {
			font-size: 16px;
		}
		.name-group {
			display: flex;
		}
		.column-headings {
			padding-bottom: 5px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			font-size: 10px;
			gap: 4px;

			div {
				width: 45px;
			}

			div:first-child {
				display: flex;
				align-items: center;

				img {
					margin-bottom: -2px;
					margin-right: 1px;
					height: 6px;
					filter: invert(1);
				}
			}
		}
		.athletename {
			display: inline-block;
			padding: 0 6px;
			height: 19px;
			text-transform: uppercase;
		}
		.t100-points {
			margin-right: 4px;
			background-color: #02162d;
			color: #00ffff;
			width: 45px;
			height: 19px;
			min-width: 45px;
			max-width: 45px;
			text-align: center;
		}
		.athletetime {
			width: 60px;
			text-align: right;
			margin-right: 0px;
			padding: 0 4px;
			margin-right: 4px;
		}
		.athletepoints {
			width: 45px;
			text-align: center;
			padding: 0 0px;
			background-color: var(--pto-red);
			color: white;
			height: fit-content;
			flex-shrink: 0;
		}

		@media (min-width: 768px) {
			&:has(> .column-headings) {
				margin-top: -41px;
			}

			.column-headings {
				div {
					font-size: 13px;
					width: 67px;

					img {
						width: 45px;
						height: 8px !important;
					}
				}
			}

			.t100-points,
			.athletepoints {
				width: 67px;
				min-width: 67px;
				max-width: 67px;
			}
		}
	}

	.athlete-pic {
		width: 90px;
		height: 90px;

		@media (max-width: 768px) {
			width: 50px;
			height: 50px;
		}
	}

	&.shortcuts {
		.race-list {
			flex-wrap: unset;
			overflow-x: auto;
			width: 100%;
			scroll-snap-type: x mandatory;
			position: relative;
		}
		.race-container {
			scroll-snap-align: start;
			display: flex;
			flex-shrink: 0;
		}
		.race {
			width: unset;
			border: 0;
			padding: 0;
			flex-shrink: 0;
			border: 1px solid var(--border-color);
		}

		.racename {
			justify-content: start;
		}

		.athletename {
			max-width: unset;
			white-space: nowrap;
		}
	}
}

.podiums {
	text-align: center;
	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
	}
	td:nth-child(1),
	th:nth-child(1) {
		width: 40px;
	}
	td:nth-child(2),
	th:nth-child(2) {
		width: 160px;
	}
	td:nth-child(4),
	th:nth-child(4) {
		width: 50px;
	}
	td:nth-child(5),
	th:nth-child(5) {
		width: 50px;
	}
	td:nth-child(6),
	th:nth-child(6) {
		width: 50px;
	}
}

.first-place,
.second-place,
.third-place {
	width: 15px;
	height: 15px;
	border-radius: 9px;
	margin: 0 auto;
	font-size: 10px;
	font-weight: 900;
	font-family: Lato, sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 768px) {
		width: 21px;
		height: 21px;
		border-radius: 12px;
		font-size: 13px;
	}
}

.first-place {
	background-color: #d10a0c;
	color: white;
}

.second-place {
	background-color: #888888;
	color: white;
}

.third-place {
	background-color: #eaeaea;
	color: #101010;
}

.races-list {
	td:nth-child(1),
	th:nth-child(1) {
		text-align: center;
		width: 50px;
	}
	td:nth-child(2),
	th:nth-child(2) {
		text-align: center;
		width: 40px;
	}
	@media (max-width: 768px) {
		td:nth-child(4),
		th:nth-child(4) {
			display: none;
		}
		td:nth-child(5),
		th:nth-child(5) {
			display: none;
		}
	}
}

.race-results-container {
	width: 100%;
	overflow-x: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.race-results {
	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
		font-size: 14px;
	}
	td:nth-child(2) {
		text-transform: uppercase;
	}

	h2 {
		font-size: 27px !important;
		margin-bottom: 12px;
	}

	.points {
		color: #888;
		font-size: 12px;
		margin-left: 4px;
		margin-top: 1px;
	}

	.athlete-pic {
		width: 37px;
		height: 37px;
		font-size: 8px;
	}

	tr {
		display: flex;
	}

	tr:not([head]) td {
		font-size: 14px !important;

		.athlete-group {
			padding-block: 0.35rem !important;
		}
	}

	td {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin: 2px;
		border: 0;
		background-color: var(--info-bottom);
		padding: 0;
		border-color: #e5e5e5 !important;

		.t100-logo {
			height: 10px;
			width: 49px;
		}

		.flag-icon {
			height: 12px;
			width: 23px;
		}

		.name {
			display: flex;
			align-items: center;
		}

		.headline {
			font-weight: 700 !important;
			font-size: 14px !important;
			margin-left: 4px;
		}
	}

	td:nth-child(1) {
		width: 30px;
	}
	td:nth-child(2) {
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: start;
	}
	td:nth-child(3),
	td:nth-child(4),
	td:nth-child(5),
	td:nth-child(6),
	td:nth-child(7),
	td:nth-child(8) {
		width: 90px;
	}

	td:nth-last-child(2),
	td:last-child {
		width: 76px !important;
	}

	tr:not([head]) {
		td.t100-points {
			background-color: #02162d !important;
			color: #00ffff !important;
			font-weight: bold;
			font-size: 16px !important;
		}

		td:last-child {
			background-color: var(--pto-red) !important;
			font-weight: bold;
			font-size: 16px !important;
			color: white;
			padding-inline: 1rem;
		}
	}

	.discipline-winner {
		background-color: #f2f2f2 !important;
		font-weight: 900 !important;

		&__icon {
			max-height: 16px;
			margin-left: 5px;
		}
	}

	td.transition {
		width: 70px !important;
	}

	tr[head] {
		td {
			background-color: unset;
			padding-bottom: 5.5px !important;
		}
	}

	.athlete-group {
		.name {
			margin-left: 8px;
		}
	}

	@media (max-width: 768px) {
		.points {
			display: none;
		}
		td:nth-child(3),
		td:nth-child(4),
		td:nth-child(5),
		td:nth-child(6),
		td:nth-child(7),
		td:nth-child(8) {
			width: 80px;
		}

		td.transition {
			display: none !important;
		}
	}
	@media (max-width: 576px) {
		td:nth-child(3),
		th:nth-child(3) {
			display: none;
		}
		td:nth-child(4),
		th:nth-child(4) {
			display: none;
		}
		td:nth-child(5),
		th:nth-child(5) {
			display: none;
		}
		td:nth-child(6),
		th:nth-child(6) {
			display: none;
		}
		td:nth-child(7),
		th:nth-child(7) {
			display: none;
		}
		td.overall,
		th.overall {
			width: 86px;
		}
		.points {
			display: none;
		}
		td:nth-child(3),
		th:nth-child(3) {
			font-size: unset;
		}
		.athlete-group {
			width: 100%;
		}
	}
}

.race-participants {
	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
		font-size: 14px;
	}
	td:nth-child(2) {
		text-transform: uppercase;
	}

	.athlete-pic {
		width: 42px;
		height: 42px;
		font-size: 8px;
	}

	td {
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin: 0px;
		border: 0;
		padding: 0;
	}
	td:nth-child(1) {
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: start;
	}

	.athlete-group {
		.name {
			margin-left: 8px;
		}
	}
}

.race-past-years-winners {
	text-align: center;
	td:nth-child(1),
	th:nth-child(1) {
		width: 40px;
	}
	td:nth-child(2),
	th:nth-child(2) {
		width: 60px;
	}
	td:nth-child(3),
	th:nth-child(3) {
		text-align: left;
	}
	td:nth-child(4),
	th:nth-child(4) {
		width: 60px;
	}
	td:nth-child(5),
	th:nth-child(5) {
		text-align: left;
	}
}

.athlete-top-ranking {
	border-radius: 8px;
	overflow: hidden;
}

.snapshot-ranking,
.maternity-leave {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: unset;
	position: relative;
	scroll-snap-type: x mandatory;
	margin: 0 auto;
	width: fit-content;
	max-width: 100%;

	&.nations {
		.flag-icon {
			margin-right: 0;
			padding-bottom: 4px;
			font-size: 112px;
			@media (max-width: 768px) {
				font-size: 90px;
			}
		}
	}

	.athlete {
		scroll-snap-align: start;
		font-size: 12px;
		width: 166px;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 0 8px;
		position: relative;

		.image-container {
			position: relative;
		}

		.info {
			pointer-events: none;
			width: 100%;
			padding: 4px 12px;
			position: absolute;
			bottom: 0px;
			text-align: right;
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

			.rank {
				font-size: 40px;
				line-height: 40px;
				position: relative;
				width: fit-content;
				z-index: 1;
				.hashtag {
					font-size: 24px;
					position: absolute;
					left: -6px;
					top: -6px;
					z-index: -1;
				}
			}
		}

		.info-bottom {
			padding: 8px 4px;
			font-size: 14px;
			width: 100%;
			line-height: 18px;
			height: 3em;
			background-color: var(--info-bottom);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		.athlete-pic {
			width: 150px;
			height: 150px;

			border-radius: 0;
		}

		@media (max-width: 768px) {
			width: 136px;

			.athlete-pic {
				width: 120px;
				height: 120px;
			}

			.info {
				.rank {
					font-size: 24px;
					line-height: 24px;
					.hashtag {
						font-size: 16px;
						line-height: 16px;
						left: -4px;
						top: -4px;
					}
				}
			}
			.info-bottom {
				font-size: 11px;
				line-height: 11px;
			}
		}
	}
}

.maternity-leave {
	.athlete {
		margin: 8px 0;
	}
}

.athlete-ranking {
	.entry {
		display: flex;
		border-top: 1px solid var(--border-color);
		padding: 2px 0;
		align-items: center;
	}
	.year {
		width: 40px;
		text-align: center;
	}
	.bar {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}
	.fill {
		height: 12px;
		background-color: var(--pto-red);
		border-radius: 4px;
	}
	.points {
		color: #888;
		padding-left: 2px;
		font-size: 10px;
	}
	.rank {
		width: 50px;
		text-align: center;
	}
	.diff {
		width: 50px;
		text-align: right;
		padding-right: 12px;
	}

	.head {
		color: #888;
		border-top: unset;
		.points {
			font-size: unset;
			flex-grow: 1;
		}
	}

	&.swim {
		.fill {
			background-color: $swim-color;
		}
	}

	&.bike {
		.fill {
			background-color: $bike-color;
		}
	}

	&.run {
		.fill {
			background-color: $run-color;
		}
	}
}

.athlete-info {
	padding-block: 22px 32px;
	padding-inline: 1rem;
	font-size: 14px;

	.nopic {
		width: 240px !important;
		height: 240px !important;
	}

	.info-container {
		.athlete-pic-group {
			display: none !important;
		}

		.visit-profile {
			display: none;
		}
	}

	.t100-contract {
		display: none;
	}

	.t100-contracts {
		width: 100%;

		&__handle {
			width: 100%;
			display: flex;
			margin-top: 4px;
			align-items: center;
			border: 1px solid #e5e5e5;
			border-radius: 3px;
			padding: 10px 14px;

			img {
				max-height: 32px;
				max-width: 182px;
			}

			.name {
				font-size: 17px;
				color: #888888;
				text-transform: uppercase;
				font-weight: bold;
				padding-right: 0.75rem;
			}

			i {
				color: black;
				margin-left: auto;
				justify-self: flex-end;
				font-size: 20px;

				&:hover {
					cursor: pointer;
				}
			}
		}

		&__draw {
			background-color: #02162d;
			color: white;
			max-height: 0;
			overflow-y: hidden;
			transition: max-height 250ms;

			.t100-logo {
				svg {
					margin-left: 0 !important;
					padding-top: 4px;
					width: 62px;

					g {
						transform: scale(1.35) !important;
					}
				}

				span {
					font-size: 10px !important;
				}
			}

			.t100-logo:not(.t100-logo--gold, .t100-logo--silver) {
				--color: white !important;
			}

			&.open {
				max-height: 1200px;
			}

			img {
				max-height: 20px;
				max-width: 152px;
			}

			table {
				width: 100%;
			}

			thead tr {
				border-bottom: 1px solid #888888;
				font-weight: bold;
			}

			td,
			th {
				padding: 9px 14px;
				font-size: 17px;
			}

			th {
				padding-bottom: 12px;
			}

			td:first-child,
			th:first-child {
				width: 114px;
			}

			tbody tr:last-child td {
				padding-bottom: 16px;
			}

			tbody {
				td {
					a.link-anchor {
						color: white;
						text-decoration: none;
						font-size: 0.9rem;
						--pto-red: #00ffff;

						&:after {
							color: var(--pto-red);
						}
					}
				}
			}

			@media (min-width: 768px) {
				.t100-logo {
					margin-top: 8px;
				}
			}
		}
	}

	.equipments,
	.info {
		display: flex;
		gap: 7px;
		flex-direction: column;
	}

	.attribute-group {
		width: 100%;
		justify-content: start;
		display: grid !important;
		margin-top: 22px !important;
		grid-template-rows: auto auto;
		padding-bottom: 28px;
		grid-template-columns: repeat(2, minmax(0, 1fr));

		.attribute {
			padding-inline: 0 !important;
			flex-direction: unset !important;
			grid-column: 1 / 2;

			.name,
			.value {
				font-size: 16px !important;
			}

			.name {
				margin-right: 3px;
			}

			&.equipment {
				grid-column: 2 / 3;
			}
		}
	}

	.ranks {
		padding-top: 1rem;
		display: grid;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 12px;
		width: 100%;
	}

	@media (max-width: 768px) {
		font-size: 12px;
	}

	@media (min-width: 768px) {
		.ranks {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	.country {
		display: flex;
		margin-bottom: 18px;
		grid-column: 1 / -1 !important;

		.flag-icon {
			max-width: 27px;
			height: 20px;
		}

		.name {
			color: black !important;
			padding-left: 9px;
			text-transform: capitalize !important;
		}
	}

	.social {
		margin-top: 7px;
		justify-content: flex-start;

		.name {
			margin-right: 10px !important;
		}
	}

	.rank {
		border: 1px solid #e5e5e5;
		padding-block: 8px;
		padding-inline: 0.5rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		border-radius: 3px;

		.ranking-number {
			color: rgb(58, 58, 58) !important;
		}

		.name {
			color: #888888 !important;
			text-transform: uppercase;
			font-size: 15px !important;
			font-weight: bold !important;
			text-align: center;
			padding-top: 6px;
			display: flex;
			align-items: center;

			i {
				margin-left: 6px;
				color: var(--pto-red);
			}
		}

		&--world {
			.name {
				i {
					color: white;
				}
			}
		}

		&--discipline {
			.name {
				i {
					color: var(--color);
				}
			}
		}

		@media (min-width: 992px) {
			.name {
				i {
					opacity: 0;
					width: 0;
					margin-left: 0;
					transition: all 350ms;
				}
			}

			&:hover {
				&.rank--world {
					.ranking-number {
						color: white;
					}
				}

				.name {
					i {
						opacity: 1;
						width: revert;
						margin-left: 10px;
					}
				}
			}
		}

		.ranking-number:before {
			color: var(--color) !important;
		}

		&--world {
			background-color: #d10b0c !important;

			.name,
			.ranking-number {
				color: white !important;
			}

			.ranking-number:before {
				color: white !important;
				opacity: 0.5;
			}
		}
	}

	h2.headline {
		font-weight: 900 !important;
		font-size: 34px;

		@media (max-width: 768px) {
			padding-block: 1.5rem 0.5rem;
		}
	}

	.info-container {
		padding-left: 2rem;

		@media (max-width: 768px) {
			width: 100%;
			align-items: center;
			padding-left: 0;
		}
	}

	.info-block {
		display: flex;

		@media (max-width: 768px) {
			width: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 {
				text-align: center;
			}
		}
	}

	.entry {
		display: flex;
		padding: 1px 0;
		align-items: center;
		&.swim {
			.fill {
				background-color: $swim-color;
			}
		}

		&.bike {
			.fill {
				background-color: $bike-color;
			}
		}

		&.run {
			.fill {
				background-color: $run-color;
			}
		}
	}

	.bar {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}
	.fill {
		height: 12px;
		background-color: $palette9;
		border-radius: 4px;
	}
	.points {
		color: #888;
		padding-left: 2px;
		font-size: 10px;
	}
	.discipline {
		width: 40px;
		text-align: right;
		margin-right: 4px;
	}

	.ranking {
		font-weight: bold;
		color: $section;
		background-color: var(--text);
		padding: 1px 8px;
		border-radius: 8px;
	}
	.athlete-pic {
		width: 240px;
		height: 240px;
	}
	.mini-info {
		display: flex;
		.material-icons-round {
			font-size: 18px;
			margin-right: 4px;
		}
		.item {
			display: flex;
			align-items: center;
			padding-right: 12px;
		}
	}

	h1 {
		font-size: 2.5em;
	}

	.attribute-group {
		display: flex;
		@media (max-width: 768px) {
			flex-wrap: wrap;
			justify-content: center;
		}
		.attribute {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			padding: 0 0.5em;
			font-weight: bold;

			@media (max-width: 768px) {
				align-items: center;
			}
			.flag-icon {
				font-size: 22px;
			}
			.name {
				font-family: Lato, sans-serif;
				text-transform: uppercase;
				font-size: 0.9em;
				color: var(--subtext);
				@media (max-width: 768px) {
				}
			}
			.value {
				font-size: 1.2em;
				text-align: top;
				span {
					font-size: 0.7em;
					font-weight: lighter;
				}
			}
		}
	}

	.rings {
		overflow: hidden;
		display: flex;
		gap: 1.25rem;
		position: relative;
		margin-top: 1rem !important;

		@media (max-width: 768px) {
			justify-content: center;
		}

		.name {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 0.9em;
			color: var(--color);
		}

		.ranking-number:before {
			color: var(--color);
		}
	}
}

.athlete-stats {
	display: flex;
	flex-wrap: wrap;
	& > div {
		padding: 1px 16px;
		width: 50%;
		flex-shrink: 0;
		flex-grow: 0;
	}
	& > div:nth-child(odd) {
		text-align: right;
	}
	& > div:nth-child(even) {
	}
}

.athlete-with {
	display: flex;
	font-size: 12px;
	justify-content: center;
	flex-wrap: wrap;
	.athlete {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 2px 8px;
		width: 120px;
	}
	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.athlete-pic {
		width: 120px;
		height: 120px;
	}
	.number {
		color: $palette9;
		&.below {
			color: $palette0;
		}
	}

	.info-bottom {
		padding: 2px 4px;
		height: 3em;
		line-height: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
}

.social {
	display: flex;
	flex-wrap: wrap;
	height: 20px;
	@media (max-width: 768px) {
		justify-content: center;
	}
	a {
		display: flex;
		align-items: center;
	}
	.social-link {
		margin: 0 6px;
		i {
			font-size: 20px;
		}
		img {
			width: 20px;
			margin-right: 2px;
			filter: grayscale(50%);
			&:hover {
				filter: unset;
			}
		}
	}
	&:not(:empty) {
		&:before {
			font-weight: bold;
			margin-right: 8px;
		}
	}
}

#dialog-submit-athlete-pic {
	.body {
		-webkit-user-select: none;
		user-select: none;
	}
}
.edit-image {
	cursor: pointer;
	.transform-image {
		position: absolute;
		img {
		}
	}
	.mask {
		box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.8);
		background-color: transparent;
		z-index: 2;
		outline: 6px solid $palette6;
		pointer-events: none;
	}
}

.edit-image {
	height: calc(100vh - 300px);
	width: calc(80vw - 40px);
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		pointer-events: none;
		user-select: none;
		-webkit-user-select: none;
	}

	.resize-drag {
		background-color: rgba(91, 129, 243, 0.64);
		color: white;
		font-size: 20px;
		font-family: sans-serif;
		border-radius: 8px;
		padding: 20px;
		touch-action: none;

		position: absolute;

		box-sizing: border-box;
		z-index: 1;
		pointer-events: all;
	}
}

.athlete-pic {
	width: 120px;
	height: 120px;
	text-align: center;
	font-size: 10px;
	display: block;
	color: #888;
	position: relative;
	overflow: hidden;
	img {
		width: 100%;
	}
}

.athlete-pic-group {
	position: relative;
	display: table;

	.nopic {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--border-color);
		font-size: 10px;
		color: var(--border-color);
		padding: 4px;
		background-color: var(--background);
		border: 0;
		background-color: var(--info-bottom);

		&:after {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: calc(100%);
			height: calc(100%);
			background: url("https://content.protriathletes.org/website/assets/user-solid.svg");
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: 70%;
			filter: invert(1) brightness(0.75);
		}
	}
}

.ox-modal {
	display: none;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #000000d0;
	position: fixed;
	justify-content: center;
	align-items: center;
	z-index: 400;

	&.show {
		display: flex;
	}

	.dialog {
		min-width: 300px;
		max-width: 80vw;
		background-color: #282828;
		border-radius: 0.5rem;
		overflow: hidden;
		font-size: 1rem;
		max-height: 100vh;
		display: flex;
		flex-direction: column;

		&[large="true"] {
			width: 100%;
		}

		.header {
			user-select: none;
			-webkit-user-select: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #282828;
			padding: 0.75rem 1.5rem;
			color: white;
		}
		.close-modal {
			cursor: pointer;
		}

		.body {
			flex-shrink: 1;
			overflow-y: auto;
		}

		.body {
			padding: 0rem 1.5rem;
		}

		.footer {
			padding: 1rem 1.5rem;
		}

		.footer {
			display: flex;
			justify-content: stretch;
		}

		input[type="text"],
		textarea {
			border-radius: 0.25rem;
			padding: 0.5rem;
			outline: none;
			resize: none;

			&:focus {
				border-color: $palette6;
				box-shadow: none;
			}
		}
	}
}

.admin-contributions {
	td:nth-child(5),
	th:nth-child(5) {
		width: 100px;
	}
	td:nth-child(6),
	th:nth-child(6) {
		width: 100px;
	}
}

.standing {
	table {
		width: 100%;
	}
	th {
		font-weight: unset;
		color: #888;
	}
	td {
		border-top: 1px solid var(--border-color);
	}
	td:nth-child(1),
	th:nth-child(1) {
		text-align: center;
		width: 40px;
	}
	td:nth-child(3),
	th:nth-child(3) {
		text-align: center;
		width: 60px;
	}
	border-radius: 4px;
}

.athlete-list {
	&.table {
		th {
			font-weight: unset;
			color: #888;
		}
		td:nth-child(1),
		th:nth-child(1) {
			text-align: center;
			width: 60px;
		}
		td:nth-child(3),
		th:nth-child(3) {
			text-align: center;
			width: 60px;
		}
		td:nth-child(4),
		th:nth-child(4) {
			text-align: center;
			width: 80px;
		}
		td:nth-child(5),
		th:nth-child(5) {
			text-align: center;
			width: 60px;
		}
		td:nth-child(6),
		th:nth-child(6) {
			text-align: center;
			width: 60px;
		}
		td:nth-child(7),
		th:nth-child(7) {
			text-align: center;
			width: 60px;
		}
	}
}

.ranking-number {
	font-family: Avenir Next LT W04 Heavy Cn It, sans-serif;
	text-transform: uppercase;

	&:before {
		content: "#";
		font-size: 20px;
		position: absolute;
		left: -6px;
		top: -6px;
		color: $pto-red;
	}

	font-size: 30px !important;
	line-height: 30px;
	position: relative;
	width: fit-content;
	margin: 0 auto;
}

.rankings {
	width: 100%;
	display: flex;
	flex-direction: column;

	.nopic {
		height: 50px !important;
	}

	.p1 > .name {
		display: flex;
		width: 100%;
		align-items: center;

		.flag-icon {
			height: min-content;
		}
	}

	.t100-winner {
		border: 1px solid #dcdcdc;
		border-radius: 3px;
		height: 48px;
		margin-left: auto;
		width: 142px;
		padding-inline: 6px 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		i {
			&:hover {
				cursor: pointer;
			}
		}

		& + .t100-logo {
			margin-left: 1rem !important;
		}

		&__results {
			display: none;
			box-shadow: 0px 0px 6px #00000050;
			width: 100%;
			position: absolute;
			top: 49px;
			gap: 2px;
			left: 0;
			padding: 9px;
			padding-top: 11px;
			background-color: white;
			flex-direction: column;
			align-items: center;

			&.show {
				display: flex;
			}

			.t100-logo {
				position: relative;
				padding-bottom: 4px;
				margin-top: 0 !important;

				&:not(:first-child) {
					margin-top: 6px !important;
				}

				svg {
					width: 60px !important;
					margin-inline: 0 !important;
				}

				g {
					transform: scale(1.35) !important;
				}

				span {
					margin-top: -4px !important;
					max-width: 110px;
					text-align: center;
				}

				&:last-child {
					padding-bottom: 0;
				}

				&:not(:last-child) {
					&::after {
						content: "";
						position: absolute;
						bottom: -3px;
						display: block;
						height: 1px;
						width: 110px;
						border-bottom: 1px solid #e5e5e5;
					}
				}
			}
		}

		.t100-logo {
			margin-left: 0 !important;
			margin-top: 6px;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.flag-icon {
		@media (min-width: 768px) {
			margin-right: 10px !important;
			margin-left: 3px !important;
			width: 20px !important;
			height: 12px !important;
		}
	}

	.t100-logo {
		max-height: 16px;
		max-width: 80%;
		display: block;
		margin-top: 3px;
		margin-bottom: 2px;
		flex-basis: 100%;
		max-height: 9px;

		@media (max-width: 768px) {
			svg {
				width: 44px;
				padding-top: 4px;
				margin-right: 3px;

				g {
					transform: scale(1.35) !important;
				}
			}

			span {
				font-size: 9px !important;
			}
		}

		@media (min-width: 768px) {
			display: inline-block;
			margin-left: auto;
			max-height: 42px;
			max-width: 210px;
			flex-basis: unset;
		}
	}

	.thead,
	.trow {
		display: flex;

		& > div {
			margin: 4px 2px 0px 2px;
			padding: 0px 0;
			display: flex;
			justify-content: center;
			align-items: center;

			&:nth-child(1) {
				text-align: center;
				width: 52px;
				padding-left: 7px;
				flex-shrink: 0;
			}

			&:nth-child(2) {
				flex-grow: 1;
				justify-content: flex-start;
			}

			&:nth-child(3) {
				padding-inline: 6px;

				@media (min-width: 768px) {
					line-height: 14px;
				}
			}

			&:nth-child(3),
			&:nth-child(4) {
				text-align: center;
				width: 80px;
				flex-shrink: 0;
			}

			&:nth-child(4) {
				width: 60px !important;
			}

			&:nth-child(5) {
				text-align: center;
				width: 32px;
				flex-shrink: 0;
				font-size: 20px;
				cursor: pointer;
			}

			@media (max-width: 768px) {
				&:nth-child(1) {
					.ranking-number {
						&:before {
							left: -4px;
							top: -4px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	.trow {
		& > div {
			background-color: var(--info-bottom);
			&:nth-child(2) {
				font-weight: 900;
				text-transform: uppercase;
				font-size: 20px;

				.name {
					margin: 0 8px;
				}

				@media (min-width: 768px) {
					.name {
						width: 100%;
						display: flex;
						align-items: center;
					}

					.t100-logo {
						flex-direction: column;

						span {
							font-size: 8px !important;
						}
					}
				}

				@media (max-width: 768px) {
					font-size: 14px;

					& .name {
						margin: 0 6px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
					}
				}
			}
			&:nth-child(3) {
				font-size: 20px;
				background-color: $pto-red !important;
				color: white;
			}
			@media (max-width: 768px) {
				&:nth-child(3) {
					font-size: 14px;
					width: 70px;
				}
			}
		}
	}

	.details {
		transition: max-height 250ms;
		max-height: 0;
		&.show {
			max-height: 600px;
		}
		overflow: hidden;
		margin-inline: -1px;
	}

	.rank-details {
		margin: 0px 2px 8px 2px;
		padding: 8px 0px;
		border-left: 1px solid var(--border-color);
		border-right: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		background-color: #181818;
		border: 1px solid #1a4f5d !important;

		@media (min-width: 768px) {
			margin-bottom: 20px;
		}

		.group {
			background-color: #222;
			padding: 4px 0;
		}

		.hide-for-desktop {
			@media (min-width: 768px) {
				display: none !important;
			}
		}

		.rank-row {
			display: flex;
			padding: 2px 16px;

			&--additional-score {
				img {
					width: 13px;
					margin-left: 6px;
					margin-bottom: 2px;
				}

				.tooltip-button {
					padding: 0;
					z-index: 1000000;

					&:hover {
						cursor: pointer;
					}
				}

				.tooltip {
					left: 18px;
					margin-right: 16px;
					bottom: 37px;
					color: white;
					max-width: 260px;
					z-index: 1000002;
					border-color: white;
					background-color: #02162d !important;

					b {
						max-width: 160px;
						display: block;
					}

					@media (min-width: 768px) {
						left: 234px;
						bottom: 17px;
					}
				}

				&.rank-row:not(.head) div.points {
					background-color: #095662 !important;
				}
			}

			&.won-championships {
				width: min-content;
				gap: 23px;
				padding-block: 12px 10px;

				& > .t100-logo {
					flex-direction: column !important;
					max-height: unset !important;
					height: unset !important;

					img {
						height: 9px !important;
						width: 48px !important;
						margin-right: 0 !important;
					}

					span {
						color: white !important;
						margin-top: 2px !important;
						text-align: center;
					}
				}
			}

			&.title {
				flex-direction: column;
				justify-content: center;
				text-align: center;
				display: inline-flex;
				margin: 0 auto;
				align-items: center;
				width: 100%;
				text-transform: uppercase;
			}
			&.head {
				font-weight: bold;
				border-bottom: 1px solid var(--border-color);

				div {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				img {
					width: 46px;
					height: 8.5px;
				}
			}

			.date {
				width: 80px;
				flex-shrink: 0;
				justify-content: unset !important;
			}

			.winner {
				margin-top: 18px;
			}

			.race {
				flex-grow: 1;
				justify-content: unset !important;
			}

			.time {
				width: 78px;
				flex-shrink: 0;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.points {
				width: 80px;
				flex-shrink: 0;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.red {
				background-color: var(--pto-red);
			}

			@media (max-width: 768px) {
				.time {
					width: 68px !important;
				}

				.position {
					width: 17px !important;
					justify-content: center !important;
				}
			}
		}
	}

	.athlete-pic {
		width: 50px;
		height: 50px;
		@media (max-width: 768px) {
			width: 45px;
			height: 45px;
		}
	}

	&.table {
		width: 100%;
		display: flex;
	}
}

.move-up {
	font-size: 12px;
	color: $palette9;
}
.move-down {
	font-size: 12px;
	color: var(--pto-red);
}

.time-up {
	color: $palette9;
}
.time-down {
	color: var(--pto-red);
}

#form-rankings,
#form-athlete-list,
#form-alltime {
	.select {
		max-width: 150px;
		select {
			width: 100%;
		}
	}
}

.ring_progress {
	width: 60px;
	height: 70px;
	margin-right: 10px;

	.meter,
	.value {
		fill: none;
		transform: rotate(-90deg);
	}

	.meter {
		stroke: var(--ring-color);
	}

	.value {
		stroke: $overall-color;
		stroke-linecap: round;
	}
	.percent {
		fill: $overall-color;
		font-size: 28px;
		font-weight: bold;
	}
	.title {
		fill: $overall-color;
		font-size: 24px;
	}

	&.swim {
		.value {
			stroke: $swim-color;
		}
		.percent,
		.title {
			fill: $swim-color;
		}
	}
	&.bike {
		.value {
			stroke: $bike-color;
		}
		.percent,
		.title {
			fill: $bike-color;
		}
	}
	&.run {
		.value {
			stroke: $run-color;
		}
		.percent,
		.title {
			fill: $run-color;
		}
	}
}

.graph-container {
	max-width: 700px;
	margin-inline: auto;
	margin-bottom: 10px;

	.graph-resizer {
		background-color: var(--background);
		position: relative;
		width: 100%;
		padding-top: 30%;
		svg {
			position: absolute;
			top: 0;
		}
	}

	.grid {
		text {
			font-size: 12px;
			fill: #aaa;
			alignment-baseline: middle;
			text-anchor: end;
		}
	}

	.swim {
		path {
			fill: none;
			stroke: $swim-color;
			stroke-width: 2;
		}
		circle {
			fill: $swim-color;
			stroke-width: 2;
			stroke: var(--background);
		}

		&.men {
			path {
				stroke: $swim-color;
			}
		}
		&.women {
			path {
				stroke: $swim-color;
			}
		}
		&.area {
			path {
				fill: $swim-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men {
				path {
					fill: $swim-color;
				}
			}
			&.women {
				path {
					fill: $swim-color;
				}
			}
		}
	}

	.bike {
		path {
			fill: none;
			stroke: $bike-color;
			stroke-width: 2;
		}
		circle {
			fill: $bike-color;
			stroke-width: 2;
			stroke: var(--background);
		}

		&.men {
			path {
				stroke: $bike-color;
			}
		}
		&.women {
			path {
				stroke: $bike-color;
			}
		}
		&.area {
			path {
				fill: $bike-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men {
				path {
					fill: $bike-color;
				}
			}
			&.women {
				path {
					fill: $bike-color;
				}
			}
		}
	}

	.run {
		path {
			fill: none;
			stroke: $run-color;
			stroke-width: 2;
		}
		circle {
			fill: $run-color;
			stroke-width: 2;
			stroke: var(--background);
		}

		&.men {
			path {
				stroke: $run-color;
			}
		}
		&.women {
			path {
				stroke: $run-color;
			}
		}
		&.area {
			path {
				fill: $run-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men {
				path {
					fill: $run-color;
				}
			}
			&.women {
				path {
					fill: $run-color;
				}
			}
		}
	}

	.overall {
		path {
			fill: none;
			stroke: $overall-color;
			stroke-width: 2;
		}

		&.men {
			path {
				stroke: $palette5;
			}
		}
		&.women {
			path {
				stroke: $palette1;
			}
		}

		&.area {
			path {
				fill: $overall-color;
				opacity: 0.5;
				stroke: none;
				stroke-width: 0;
			}
			&.men {
				path {
					fill: $palette5;
				}
			}
			&.women {
				path {
					fill: $palette1;
				}
			}
		}

		circle {
			fill: $overall-color;
			stroke-width: 2;
			stroke: var(--background);
		}
	}

	.labels {
		text {
			text-anchor: middle;
			fill: #aaa;
			font-size: 12px;
		}
	}

	.mouse {
		.popup {
			box-shadow: var(--border-color) 0 0 4px;
			filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
			font-size: 12px;
			display: none;
			rect {
				fill: #333;
			}
			.points {
				fill: white;
				font-size: 12px;
				font-weight: unset;
				text-anchor: middle;
			}
			.rank {
				fill: white;
				text-anchor: end;
			}
		}
		text {
			pointer-events: none;
			font-weight: bold;

			&.year {
				fill: #888;
				text-anchor: middle;
			}

			&.swim {
				fill: $swim-color;
			}
			&.bike {
				fill: $bike-color;
			}
			&.run {
				fill: $run-color;
			}
			&.overall {
				fill: var(--pto-red);
			}
		}
		rect {
			fill: transparent;
		}
		.hover:hover {
			.popup {
				display: block;
			}
		}
	}
}

.race-points {
	width: unset;
	td {
		padding: 0 80px 0 4px;
	}
}

.anchor {
	padding-top: calc(40px + 1.5rem);
	margin-top: -40px;
}

.stats-green {
	background-color: $palette9;
}
.stats-blue {
	background-color: $palette6;
}
.stats-pink {
	background-color: $palette1;
}

.head-vs {
	position: sticky;
	top: calc(69px);
	background-color: var(--background);
	z-index: 1;
	border-bottom: 1px solid var(--border-color);
	margin-bottom: 1.5rem;
}

.compare-row {
	.best {
		background-color: $palette9;
		color: white;
		border-radius: 4px;
	}

	.bar-percent {
		left: 50%;
		height: 18px;
		position: absolute;
		background-color: rgba(255, 255, 255, 10%);
		border-radius: 4px;
		z-index: -1;
	}

	.hth-progress {
		width: 100%;
		height: 12px;
		background-color: #444;
		position: relative;
		border-radius: 4px;
		overflow: hidden;

		div {
			position: absolute;
			height: 12px;
		}

		&.left {
			div {
				right: 0;
				background-color: $palette6;
			}
		}
		&.right {
			div {
				background-color: blue;
				background-color: $palette13;
			}
		}
	}
}

.races-compare {
	td:nth-child(1),
	th:nth-child(1) {
		text-align: center;
		width: 70px;
	}
	td:nth-child(3),
	th:nth-child(3) {
		text-align: center;
		width: 70px;
	}
}

.hth-info {
	td:nth-child(1),
	th:nth-child(1) {
		text-align: right;
		width: 41.666%;
	}
	td:nth-child(2),
	th:nth-child(2) {
		text-align: center;
		width: 16.6666%;
	}
	td:nth-child(3),
	th:nth-child(3) {
		text-align: left;
		width: 41.666%;
	}
}

.table-time-predictor {
	td:nth-child(1),
	th:nth-child(1) {
		text-align: right;
		width: 25%;
	}
	td:nth-child(2),
	th:nth-child(2) {
		text-align: center;
		width: 50%;
	}
	td:nth-child(3),
	th:nth-child(3) {
		text-align: left;
		width: 25%;
	}
}

.head-to-head {
	.athlete-info {
		text-align: center;
		.info-block {
			flex-direction: column;
			align-items: center;
		}

		.attribute-group {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

.head-to-head-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.athlete-pic {
		width: 120px;
		height: 120px;
	}

	.athlete {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 12px;
		width: 120px;
	}

	.info-bottom {
		padding: 2px 4px;
		height: 3em;
		line-height: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
	}
}

.social-list {
	display: flex;
	width: fit-content;
	max-width: 100%;
	margin: 0 auto;
	justify-content: center;
	flex-wrap: wrap;

	.social-post {
		width: 25%;
		padding: 8px 16px;
		flex-shrink: 0;
		width: 300px;

		.img-container {
			border: 1px solid white;
			border-bottom: 0;
			width: 100%;
			padding-top: 56.25%;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				object-fit: cover;
			}
		}
		.title-container {
			border: 1px solid white;
			background-color: #00beff;
			font-weight: bold;
			padding: 4px 8px;
			line-height: 1.25rem;

			.social-title {
				height: calc(2.5rem);
				width: 100%;
				text-overflow: ellipsis;
				white-space: normal;
				-webkit-line-clamp: 2;
				overflow: hidden;
				-webkit-box-orient: vertical;
				display: -webkit-box;
				color: white;
			}
		}
	}
}

.youtube-feed {
	max-width: 100%;
	font-size: 13px;

	.yt-list {
		display: flex;
		flex-wrap: unset;
		overflow-x: auto;
		width: fit-content;
		max-width: 100%;
		margin: 0 auto;
		scroll-snap-type: x mandatory;
		position: relative;
	}
}

.youtube-page {
	.yt-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.youtube-video {
			padding-bottom: 16px;
		}

		.youtube-video {
			width: 25%;
			padding: 8px 24px;
			.img-container {
				width: 100%;
				padding-top: 56.25%;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
				}
			}

			@media (max-width: 1200px) {
				width: 33.33%;
			}

			@media (max-width: 992px) {
				padding: 8px 12px;
				width: 50%;
			}

			@media (max-width: 768px) {
				padding: 8px 0px;
				width: 100%;
			}
		}
	}
}
#filter-videos {
	width: 100%;
	max-width: 400px;
}

.youtube-video {
	scroll-snap-align: start;
	padding: 0 8px;
	text-decoration: none !important;

	img {
		width: 200px;
		height: 112px;
		object-fit: cover;
		margin-bottom: 4px;
	}
	.youtube-author {
		width: 175px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow-x: hidden;
		color: #fff !important;
	}

	.youtube-title {
		color: #888 !important;
		width: 175px;
		text-overflow: ellipsis;
		white-space: normal;
		-webkit-line-clamp: 2;
		overflow: hidden;
		-webkit-box-orient: vertical;
		display: -webkit-box;
	}
}

.instagram-feed {
	max-width: 100%;
	display: flex;
	font-family: Arial, sans-serif;
	overflow-x: auto;
	margin: 24px auto;
	scroll-snap-type: x mandatory;

	font-size: 13px;

	.instagram-image {
		scroll-snap-align: start;
		margin-right: 8px;
		text-decoration: none !important;
		color: #888 !important;

		img {
			width: 160px;
			height: 160px;
			object-fit: cover;
			margin-bottom: 4px;
		}

		.instagram-title {
			width: 150px;
			text-overflow: ellipsis;
			white-space: normal;
			-webkit-line-clamp: 2;
			overflow: hidden;
			-webkit-box-orient: vertical;
			display: -webkit-box;
		}
	}
}

.flag-icon {
	background-size: cover !important;
}

.sponsor {
	width: 120px;
	height: 80px;
	padding: 8px 12px;
	position: relative;
	img {
		opacity: 0.7;
		width: 100%;
		object-fit: contain;
		height: 100%;

		&:hover {
			opacity: 1;
		}

		&[alt]:after {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			font-size: 16px;
			background-color: var(--background);
			content: attr(alt);
		}
	}
}

.compare-table {
	div[better] {
		background-color: $pto-red;
	}
}

#yt-player {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.radio-buttons {
	input[type="radio"] {
		margin: 0 2px;
		padding: 2px 8px;
		color: white;
		background-color: #444;
		appearance: unset;
		cursor: pointer;
		outline: 0;

		&:checked {
			color: black;
			background-color: white;
		}

		&:after {
			content: attr(label);
			display: flex;
		}
	}
}

.radio-group {
	display: flex;
	border-radius: 6px;
	border: 1px solid var(--border-color);
	overflow: hidden;

	background-color: var(--select-color);

	input[type="radio"] {
		width: fit-content;
		-moz-appearance: unset;
		-webkit-appearance: unset;
		appearance: unset;
		display: flex;
		color: white;
		border-radius: 0;
		cursor: pointer;
		box-shadow: unset;

		padding: 2px 8px;

		&:checked {
			background-color: #555;
		}

		&:focus {
			outline: 0;
		}

		&:after {
			content: attr(label);
			display: flex;
			align-items: center;
		}
	}

	input[type="radio"]:not(:first-child) {
		border-left: 1px solid var(--border-color);
		&:checked {
			border-left: 1px solid transparent;
		}
	}
}

@import "./server/components/athletes";
@import "./server/components/brands";
@import "./server/components/races";
@import "./server/components/t100Logo";
@import "./server/components/rankings";

body.embed {
	.main {
		background-color: white;
	}
	.signature {
		color: #888;
		margin-bottom: 10px;
		display: flex;
		justify-content: flex-end;
		.pto-logo-long {
			height: 18px;
			filter: brightness(50%);
		}
	}

	border: 1px solid var(--border-color);

	&.nosignature {
		.signature {
			display: none;
		}
		border: 0;
	}

	.section-bottom {
		border: 0 !important;
	}

	.headline {
		font-family: Lato, sans-serif;
		font-weight: bold;
	}

	&.bright {
		background-color: #ffffff;
		color: #1d1d1f;

		a,
		.link-anchor {
			color: #1d1d1f;
			&:hover {
				color: var(--pto-red) !important;
			}
		}
		.ring_progress .meter {
			stroke: #ddd;
		}
		.table {
			color: #1d1d1f;
		}
		.race-results tr:not([head]) td:nth-child(7) {
			background-color: var(--pto-red);
			font-weight: bold;
			color: white;
		}

		.race-results tr[head] td:nth-child(7) {
			color: #1d1d1f;
		}
		border: 1px solid rgba(0, 0, 0, 10%);
	}

	height: fit-content;
	max-height: unset;
	display: flex;
	align-items: flex-end;
	.content-area {
		padding-top: unset;
		max-height: unset;
		min-height: unset;
	}
	.main-area {
		.main {
			margin: unset;
		}
	}
}

.we-are-pto {
	background-position: 50%;
	background-size: cover;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url(https://content.protriathletes.org/wp-content/uploads/2020/07/explainer-crop.jpg);
	.content {
		padding: 8rem 1rem;
		display: flex;
		flex-direction: column;
		text-align: center;
		max-width: 800px;
		margin: 0 auto;
		color: white;
	}
}

.stream-amg-embed {
	width: 100%;
	height: 100%;
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

html.bright {
	.topbar {
		.menu > a {
			transition: color var(--transition-duration);
			--transition-duration: 250ms;
			background-color: white;
			position: relative;

			&::after {
				transition: visibility 0ms var(--transition-duration), opacity var(--transition-duration);
				background-color: var(--pto-red);
				visibility: hidden;
				position: absolute;
				height: 6px;
				width: 100%;
				content: "";
				opacity: 0;
				bottom: 0;
				left: 0;
			}
		}

		.menu > a:hover {
			color: var(--pto-red);

			&:after {
				transition: visibility 0ms 0ms, opacity var(--transition-duration);
				visibility: visible;
				opacity: 1;
			}
		}
	}

	#side-menu {
		.menu {
			background: white;
		}
	}

	#search-input {
		color: white;
	}
	#filter-videos {
		background-color: white;
	}
	.athlete-pic {
		background-color: #e0e0e0;
		background: url("https://content.protriathletes.org/website/assets/profile_background.jpg?v=1");
		background-size: 100%;
		border-radius: 7px;
	}

	.nopic {
		background-color: #e0e0e0;
		border-radius: 7px;
	}

	.search-result--athlete {
		.nopic {
			height: 50px;

			@media (min-width: 768px) {
				height: 90px;
			}
		}
	}

	.athlete {
		.athlete-pic {
			border-radius: 8px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		.info-bottom {
			background-color: #202020;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			a {
				color: white;
			}
			a:hover {
				color: var(--pto-red);
			}
		}
	}

	#collinscup-athletes {
		.cc-team-name {
			color: white;
			background-color: #202020;
			width: 100%;
			text-transform: uppercase;
		}
	}

	.collins-cup-team {
		.header {
			.name {
				color: white;
			}
		}
		.athlete-section {
			color: white;
		}
	}

	.link-anchor {
		&:hover {
			color: var(--pto-red) !important;
		}
		&:before {
			content: unset;
			background-color: rgba(0, 0, 0, 0.15);
		}
	}
	.section-bottom {
		background-color: white;
		border-top: 1px solid var(--border-color);
		&.no-border {
			border: 0;
		}
		&.border-x {
			border-left: 1px solid var(--border-color);
			border-right: 1px solid var(--border-color);
		}
		&.bright {
			background-color: #f2f2f2;
		}
	}

	.invert {
		background-color: #202020;
		a {
			color: #f0f0f0;
		}

		.link-anchor {
			&:before {
				content: unset;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.snapshot-ranking {
		.athlete {
			.info {
				background-image: linear-gradient(transparent, 80%, rgba(0, 0, 0, 0.5));
			}
		}
	}

	.pto-stats,
	.pto-world-rankings__logo {
		span {
			color: black !important;
			margin-left: 12px;
			font-size: 41px;
			content: "WORLD RANKINGS";
			font-family: Avenir Next, sans-serif;
			font-weight: bold;
			transform: translateY(2px);
			white-space: nowrap;
		}

		&:after {
			color: black;
		}
	}

	.rankings {
		.trow {
			margin-top: 0.25rem;
			border: 1px solid var(--border-color);
			& > div {
				background-color: white;
				margin: 0;
			}
		}

		.points {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@media (min-width: 768px) {
				.hide-for-desktop {
					display: none !important;
				}
			}

			@media (max-width: 768px) {
				.hide-for-desktop {
					max-height: 23px !important;
					display: flex;
					align-items: center;
				}

				.t100-logo {
					--color: white !important;
					flex-direction: column !important;
					max-height: 23px !important;
					margin-top: 0 !important;

					svg {
						height: 15px !important;
					}

					span {
						font-size: 8px !important;
						margin-top: -2px !important;
					}
				}
			}
		}

		.rank-details {
			border-color: #202020;
			background-color: #e0e0e0;
			background-color: #202020;
			color: white;
			.rank-row.head {
				border-color: #444;
			}
			.group {
				background-color: #d0d0d0;
				background-color: #303030;
				color: white;
			}
			.red {
				color: white;
			}
			a {
				color: white;
			}
			a.text-grey {
				color: #888;
			}
		}
	}

	#athlete-popover {
		background-color: white;
		box-shadow: 0 0 16px #444;
	}

	.bg-white {
		background-color: white;
	}

	.radio-group {
		input[type="radio"] {
			color: var(--text);
			&:checked {
				background-color: var(--pto-red);
				color: white;
			}
		}
	}

	.scroll-anchors {
		.scroll-anchor-left,
		.scroll-anchor-right {
			background: rgba(0, 0, 0, 0.8);
		}
	}

	.background-distress-bright {
		background: white;
	}

	.sponsor {
		img {
			filter: invert(1);
		}
	}

	.graph-container .graph-resizer {
		background-color: unset;
	}
	.compare-row .best {
		background-color: var(--pto-red);
		font-weight: bold;
	}

	.race-results {
		tr td {
			margin: 0;
			margin-bottom: 0px;
		}
		tr:not([head]) td {
			background-color: unset;
			border-top: 1px solid var(--border-color);
		}
	}

	.youtube-video {
		.youtube-author {
			color: var(--text) !important;
		}
	}

	.ox-modal {
		.dialog {
			background-color: white;
			.header {
				background-color: white;
				color: #222;
			}
		}
	}
}

#pto-event-container {
	width: 100%;
	height: 100%;
}

.points-system {
	max-width: 75ch;
	ol {
		padding-inline-start: 2ch;
	}
	li {
		margin-bottom: 2rem;
	}

	table {
		text-align: center;
		td,
		th {
			padding: 0.25rem 0.5rem;
			border: 1px solid var(--text);
		}
		th {
			background-color: var(--text);
			color: white;
		}
	}
}

@import "server/components/pro-race-calendar";
@import "server/components/t100/t100-home-page";
@import "server/components/t100/t100-points-grid";
